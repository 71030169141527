const baseUrl = process.env.VUE_APP_BASE_URL;
const checkoutRedirect = process.env.VUE_APP_CHECKOUT_REDIRECT;
const TokenKey = process.env.VUE_APP_TOKEN_KEY;
const version = process.env.VUE_APP_VERSION;
const prefix = process.env.VUE_APP_PREFIX;
const IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
const stripeKey = process.env.VUE_APP_STRIPE_KEY;
const qrcodeBaseUrl = process.env.VUE_APP_QRCODE_BASE_URL;
const localeKey = process.env.VUE_APP_LOCALE;
export {
    baseUrl,
    checkoutRedirect,
    TokenKey,
    version,
    prefix,
    IMAGE_URL,
    stripeKey,
    qrcodeBaseUrl,
    localeKey
};

