<template>
    <van-form @submit="onSubmit">
        <van-field
            v-if="$store.getters.userHaveEmail"
            v-model="oldEmail"
            name="Old Email"
            :label="$t('oldEmail')"
            :placeholder="$t('oldEmail')"
            type="email"
            disabled
            required
        ></van-field>
        <van-field
            v-model="newEmail"
            name="Email"
            :label="$t('email')"
            :placeholder="$t('email')"
            type="email"
            required
        ></van-field>
        <van-field
            v-model="code"
            center
            clearable
            :label="$t('code')"
            :placeholder="$t('codePlaceholder')"
            required
        >
            <template #button>
                <van-button
                        size="small" type="primary" @click="sendCode"
                        :disabled="sentCode"
                >{{ sentCode? count + ' s':$t('sendCode') }}</van-button>
            </template>
        </van-field>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" :disabled="cantSubmit">{{$t('submit')}}</van-button>
        </div>
    </van-form>
</template>

<i18n>
{
    "en": {
        "oldEmail": "Old Email",
        "email": "New Email",
        "code": "verification code",
        "codePlaceholder": "please input verification code",
        "sendCode": "Send Code",
        "submit": "Submit"
    },
    "zh_CN": {
        "oldEmail": "旧Email",
        "email": "新Email",
        "code": "验证码",
        "codePlaceholder": "请输入验证码",
        "sendCode": "发送验证码",
        "submit": "提交"
    }
}
</i18n>

<script>
import request from "@/utils/request";
import API from "@/utils/api";
import {isEmpty} from "lodash";

export default {
    name: "EmailUpdate",
    data: () => ({
        oldEmail: '',
        newEmail: '',
        code: '',
        sentCode: false,
        count: 60,
        timer: null
    }),
    mounted() {
        this.oldEmail = this.$store.getters.getUserEmail;
    },
    computed: {
        cantSubmit() {
            return this.sentCode || isEmpty(this.code);
        }
    },
    methods: {
        onSubmit() {
            let self = this;

            request({
                ...API.UPDATE_EMAIL,
                data: {
                    email: self.newEmail,
                    code: self.code
                }
            }).then(res => {
                console.log(res);
                self.$toast.success(self.$t('emailUpdate'));
                self.$router.go(-1);
            })
        },
        sendCode() {
            let self = this;
            self.sentCode = true;
            if (self.sentCode) {
                self.timer = setInterval(() => {
                    if (self.count > 0 && self.count <= 60) {
                        self.count -= 1;
                    } else {
                        self.sentCode = false;
                        clearInterval(self.timer);
                        self.timer = null;
                        self.count = 60;
                    }
                }, 1000);
            }
            request({
                ...API.EMAIL_CODE,
                data: {
                    email: self.newEmail
                }
            }).then(res => {
                console.log(res);
                self.$toast.success(self.$t('codeSent'));
                self.sentCode = false;
            })
        }
    }
}
</script>

<style scoped>

</style>