<template>
    <van-cell-group>
        <van-cell
            :border="false"
            :title="info.name"
            title-class="store-title"
        ></van-cell>
        <van-cell
            :title="info.nameEnglish"
            title-class="store-title-english"
            :value="calPrice(info.personPrice).active"
        ></van-cell>
        <van-cell
            title-class="store-tag"
        >
            <template #title>
                <template v-for="(label, idx) in info.labels">
                    <span v-if="label.name == '小分队特别推荐'" :key="'label1-' + idx">Rice推荐</span>
                    <span v-else-if="label.name != ''" :key="'label2-' + idx">{{label.name}}</span>
                    <span v-if="info.labels && idx <= info.labels.length-2" :key="'label3-' + idx"> · </span>
                </template>
            </template>
        </van-cell>
        <van-cell
            title-class="store-business-time-wrapper"
            :value="(info.business == 1) ? $t('statusOpen') : $t('statusClose')"
        >
            <template #title>
                <div class='l'>{{ (todayBusinesses) ? $t('todayOpen') : $t('todayClose') }}</div>
                <div class="store-business-time" v-if="todayBusinesses">
                    <template v-for="(item, idx) in todayBusinesses">
                        <div class="business-time" :key="idx">
                            {{item}}
                        </div>
                    </template>
                </div>
            </template>
        </van-cell>
    </van-cell-group>
</template>

<i18n>
{
    "en": {
        "todayOpen": "Open Today ",
        "todayClose": "Closed",
        "statusOpen": "Open Now",
        "statusClose": "Closed"
    },
    "zh_CN": {
        "todayOpen": "今日营业 ",
        "todayClose": "今日不营业",
        "statusOpen": "营业中",
        "statusClose": "休息中"
    }
}
</i18n>

<script>
import {BaseMixin} from "@/utils/mixin";

export default {
    name: "StoreInfo",
    props: {
        info: Object,
        todayBusinesses: Array
    },
    mixins: [BaseMixin]
}
</script>

<style scoped>
    .store-title {
        font-size: 20px;
        color: black;
        font-weight: 600;
    }
    .store-title-english, .store-tag {
        font-size: 14px;
        font-weight: 300;
    }
    .store-business-time-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 2;
        font-size: 14px;
    }
    .store-business-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 12px;
    }
    .business-time {
        margin-right: 12px;
    }
</style>