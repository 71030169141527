<template>
    <div>
        <van-form @submit="submit" class="loginForm">
            <van-field
                v-model="mobile"
                name="mobile"
                :label="$t('mobile')"
                type="tel"
                placeholder="Mobile"
                :rules="[{ mobileValidator, required: true, message: $t('mobileMessage') }]"
            />
            <van-field
                v-if="showPassword"
                v-model="password"
                type="password"
                name="password"
                :label="$t('password')"
                :placeholder="$t('password')"
                :rules="[{ required: true, message: $t('passwordMessage') }]"
            />
            <van-field
                v-model="code"
                center
                clearable
                :label="$t('code')"
                :placeholder="$t('codePlaceholder')"
                v-if="showVerification"
            >
                <template #button>
                    <van-button
                        size="small" type="primary" @click="sendCode"
                        :disabled="sentCode"
                    >{{ sentCode? count + ' s':$t('sendCode') }}</van-button>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" :disabled="!canSubmit">{{$t('submit')}}</van-button>
            </div>
        </van-form>
        <van-row gutter="20">
            <van-col span="12" class="forget" @click="mode = 'forget'" v-show="mode !== 'forget'">{{$t('forget')}}</van-col>
            <van-col span="12" class="new-customer" @click="clickText">{{ formText }}</van-col>
        </van-row>
    </div>
</template>

<i18n>
{
    "en": {
        "mobile": "Mobile (+1)",
        "mobileMessage": "input your phone number",
        "password": "password",
        "passwordMessage": "input your password",
        "code": "verification code",
        "codePlaceholder": "please input verification code",
        "sendCode": "Send Code",
        "submit": "Submit",
        "forget": "Forget Password?",
        "newCustomer": "New customer?",
        "back": "Back to Login",
        "mobileError": "Mobile Number Error"
    },
    "zh_CN": {
        "mobile": "Mobile (+1)",
        "mobileMessage": "输入您的手机号码",
        "password": "密码",
        "passwordMessage": "输入您的密码",
        "code": "验证码",
        "codePlaceholder": "请输入验证码",
        "sendCode": "发送验证码",
        "submit": "提交",
        "forget": "忘记密码？",
        "newCustomer": "新用户？",
        "back": "返回登录",
        "mobileError": "电话号码错误"
    }
}
</i18n>

<script>
import API from "@/utils/api";
import request from "@/utils/request";
import {BaseMixin} from "@/utils/mixin";
export default {
    name: "LoginPage",
    mixins: [BaseMixin],
    data: () => ({
        mobile: '',
        password: '',
        isLogin: true,
        code: '',
        sentCode: false,
        mode: 'login',
        count: 60,
        timer: null
    }),
    computed: {
        showPassword() {
            if (this.mode === 'forget') {
                return this.sentCode;
            } else {
                return true;
            }
        },
        showVerification() {
            return this.mode === 'signup' || this.mode === 'forget';
        },
        formText() {
            if (this.mode === 'login') {
                return this.$t('newCustomer');
            }
            return this.$t('back');
        },
        canSubmit() {
            switch (this.mode) {
                case "login":
                    return true;
                case "signup":
                case "forget":
                default:
                    return !!(this.sentCode && this.code);
            }
        }
    },
    methods: {
        submit() {
            if (this.mode === 'login') {
                this.login();
            } else if (this.mode === 'signup') {
                this.signUp();
            } else {
                this.forgetPassword();
            }
        },
        login() {
            let self = this;
            request({
                ...API.USER_LOGIN,
                data: {
                    mobile: self.mobile,
                    password: self.password
                }
            }).then(res => {
                self.$store.commit('userLoginSuccess', res);
                self.goBack();
            })
        },
        signUp() {
            let self = this;
            request({
                ...API.USER_SIGNUP,
                data: {
                    mobile: self.mobile,
                    code: self.code,
                    password: self.password
                }
            }).then(res => {
                self.$store.commit('userLoginSuccess', res);
                self.goBack();
            })
        },
        forgetPassword() {
            let self = this;
            request({
                ...API.FORGET_PASSWORD,
                data: {
                    mobile: self.mobile,
                    code: self.code,
                    password: self.password
                }
            }).then(res => {
                self.$store.commit('userLoginSuccess', res);
                self.goBack();
            })
        },
        mobileValidator(val) {
            return /[0-9]{10}/.test(val);
        },
        sendCode() {
            let self = this;
            if (/[0-9]{10}/.test(this.mobile)){
                self.sentCode = true;
                if (self.sentCode) {
                    self.timer = setInterval(() => {
                        if (self.count > 0 && self.count <= 60) {
                            self.count -= 1;
                        } else {
                            self.sentCode = false;
                            clearInterval(self.timer);
                            self.timer = null;
                            self.count = 60;
                        }
                    }, 1000);
                }
                if (self.mode === 'signup') {
                    request({
                        ...API.SEND_CODE,
                        data: {
                            mobile: self.mobile,
                            for: 0
                        }
                    }).then(() => {
                        self.$toast.success(self.$t('codeSent'));
                    })
                } else {
                    request({
                        ...API.FORGET_CODE,
                        data: {
                            mobile: self.mobile,
                            for: 0
                        }
                    }).then(() => {
                        self.$toast.success(self.$t('codeSent'));
                    })
                }
            } else {
                self.$toast.fail(self.$t('mobileError'));
            }
        },
        goBack() {
            if (this.$route.query.redirect) {
                this.$router.replace(this.$route.query.redirect);
            } else {
                this.$router.go(-1)
            }
        },
        clickText() {
            if (this.mode === 'login') {
                this.mode = 'signup';
            } else {
                this.mode = 'login';
            }
        }
    }
}
</script>

<style scoped>
.new-customer, .forget {
    text-align: center;
    flex-grow: 1;
}
</style>