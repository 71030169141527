<template>
    <div class='banner' catchtap="toAlbum">
        <van-swipe :duration="2000" class="swiper">
            <template v-for="(item, index) in banners">
                <van-swipe-item :key="index">
                    <img :src="item.img" mode="aspectFill" />
                </van-swipe-item>
            </template>
        </van-swipe>
    </div>
</template>

<script>
export default {
    name: "StoreBanner",
    props: {
        banners: Array
    }
}
</script>

<style scoped>

.banner {
    height: 48vw;
    position: relative;
}

.banner .swiper, .banner img{
    width: 100%;
    height: 48vw;
}
.banner span{
    position: absolute;
    text-align: center;
    right: 190px;
    bottom: 7px;
    width: 100px;
    height: 30px;
    background: rgba(0,0,0,0.30);
    border-radius: 3px;
    font-family: SourceHanSansCN-Normal;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 30px;
}
</style>