<template>
    <div>
        <van-cell-group :title="$t('mobile')">
            <van-cell :title="$store.getters.getUserMobile"></van-cell>
        </van-cell-group>
        <van-cell-group title="Email">
            <van-cell :title="userEmail" is-link to="/email-update">
                <template #right-icon>
                    <van-icon name="edit" class="edit-icon"></van-icon>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group :title="$t('language')">
            <van-cell :title="$t('language')" is-link :value="language($i18n.locale)" @click="showLanguage = true"></van-cell>
        </van-cell-group>
        <van-action-sheet
            v-model="showLanguage"
            :actions="actions"
            :cancel-text="$t('cancel')"
            close-on-click-action
            @select="onSelectLanguage"
        />
    </div>
</template>

<i18n>
{
    "en": {
        "mobile": "Mobile",
        "language": "Language"
    },
    "zh_CN": {
        "mobile": "电话",
        "language": "语言"
    }
}
</i18n>

<script>
import { isNull } from "lodash";
import {pageMixin} from "@/utils/mixin";

export default {
    name: "ProfilePage",
    mixins: [pageMixin],
    mounted() {
        this.track();
    },
    data: () => ({
        showLanguage: false,
        actions: [
            { name: 'English', value: 'en' },
            { name: '中文', value: 'zh_CN' }
        ]
    }),
    computed: {
        userEmail() {
            if (isNull(this.$store.getters.getUserEmail)) {
                return this.$i18n.locale==='en'?'No Email':'还未绑定';
            } else {
                return this.$store.getters.getUserEmail;
            }
        },
    },
    methods: {
        language(code) {
            if (code === 'zh_CN') {
                return '中文';
            } else {
                return 'English';
            }
        },
        onSelectLanguage(action) {
            this.$store.commit('updateLocale', action.value);
        }
    }
}
</script>

<style scoped>
.edit-icon {
    font-size: 16px;
    line-height: inherit;
}
</style>