<template>
    <div class='container' :style="coupon.couponTypeCode=='YHQ'?'margin-bottom:0rpx':''">
        <div class='voucher_detail_top'>
            <img :src='coupon.image' class='voucher_detail_top_image' />
            <div class='voucher_detail_top_title'>{{coupon.couponName}}</div>
            <div class='voucher_detail_top_price' v-if="coupon.couponTypeCode !== 'YHQ'">
                <span class='gray'>有限期：{{coupon.startTime}} 至  {{coupon.endTime}}</span>
                <span>{{coupon.statusName}}</span>
            </div>
            <div class='voucher_detail_top_tips' v-show="coupon.powerDescription!==''">
                <template v-for="(item, index) in coupon.powerDescription">
                    <span v-if="index===0" :key="'item1-'+index">{{item===0?'不可叠加':'可叠加'}}</span>
                    <span v-if="index===1" :key="'item2-'+index">{{item===0?'不可退':'随时退'}}</span>
                    <span v-if="index===2" :key="'item3-'+index">{{item===0?'过期不可退':'过期可退'}}</span>
                </template>
            </div>
        </div>
        <div class="code-wrapper" v-if="canUse">
            <qrcode-vue
                v-if="!showScanner" :value="getCodeUrl" size="260" class="code-qrcode"></qrcode-vue>
            <p class="code-text">{{code}}</p>
            <StreamBarcodeReader
                v-if="showScanner"
                @decode="onDecode"
            ></StreamBarcodeReader>
            <van-button class="qrcode-button" round type="info" @click="showScanner = !showScanner" block>
                {{showScanner? 'Close Camera': 'Scan QR Code'}}
            </van-button>
            <!--            <van-button round block type="info" @click="useCoupon" :disabled="!canUse">USE COUPON</van-button>-->
        </div>
    </div>
</template>

<script>
import API from "@/utils/api";
import request from "@/utils/request";
import { formatDate, img2fullURL } from "@/utils/helper";
import QrcodeVue from "qrcode.vue";
import { qrcodeBaseUrl } from "@/utils/config";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    name: "CouponUse",
    data: () => ({
        id: '',
        coupon: {},
        code: '',
        canUse: true,
        showScanner: false
    }),
    components: {
        QrcodeVue,
        StreamBarcodeReader
    },
    mounted() {
        this.id = this.$route.params.id;
        this.loadCouponDetail();
    },
    computed: {
        getCodeUrl() {
            return qrcodeBaseUrl + '?code=' + this.code;
        }
    },
    methods: {
        loadCouponDetail() {
            let self = this;
            request({
                ...API.COUPON_USE,
                data: {
                    id: self.id
                }
            }).then(item => {
                item.image && (item.image = img2fullURL(item.image));
                if (item.couponData) {
                    if (typeof item.couponData === 'string')
                        item.couponData = JSON.parse(item.couponData);
                }
                if (item.powerDescription) {
                    if (typeof item.powerDescription === 'string')
                        item.powerDescription = item.powerDescription.split('|');
                }
                item.startTime && (item.startTime = formatDate(item.startTime));
                item.endTime && (item.endTime = formatDate(item.endTime));
                self.coupon = item;
                self.code = item.couponCode;
                const status = parseInt(item.status);
                if (status !== 7) {
                    self.canUse = false;
                }
            })
        },
        useCoupon() {
            let self = this;
            self.$dialog.confirm({
                title: 'Confirmation',
                message: 'Are you sure to use this coupon?'
            }).then(() => {
                request({
                    ...API.COUPON_CONFIRM(self.id)
                }).then(() => {
                    self.$toast.success('Success');
                    self.loadCouponDetail();
                })
            })
        },
        onDecode(decodedString) {
            console.log(decodedString);
            let self = this;
            self.$dialog.confirm({
                title: 'Confirmation',
                message: 'Are you sure to use this coupon?'
            }).then(() => {
                request({
                    ...API.COUPON_CONFIRM(self.id),
                    data: {
                        store: decodedString
                    }
                }).then(() => {
                    self.$toast.success('Success');
                    self.loadCouponDetail();
                    self.codeUsed = true;
                    self.showScanner = false;
                })
            })
        }
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 55px;
}
.gray{color: #919191;}
.voucher_detail_top{ padding: 11px 16px 0 16px; background: #fff; margin-bottom: 10px;}
.voucher_detail_top_image{ width: 100%; height: 175px; border-radius: 5px; margin-bottom: 10px;}
.voucher_detail_top_title{font-size: 18px;color: #333333; line-height: 20px;font-weight: bold;}
.voucher_detail_top_price{
    position: relative;line-height:20px; height: 20px; padding: 13px 0; font-size: 16px; overflow:hidden; border-bottom: 2px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
}
.voucher_detail_top_price span{ display: block; float: left; position: relative;}
.voucher_detail_top_tips{font-size: 14px;color: #FF4D4F;line-height: 14px; padding:16px 0; height: 14px;  overflow: hidden;}
.voucher_detail_top_tips img{ float: left; margin-right: 5px; width: 14px; height: 14px;}
.voucher_detail_top_tips span{ float: left; margin-right: 20px;}
.tabList_li_content navigator{ overflow: hidden;}

.voucher_detail_top_price1 span:first-child{
    font-size: 20px;
    color: #FF4D4F;
    text-shadow: 0 2px 4px rgba(255,77,79,0.24);
    margin-right: 15px;
}

.voucher_detail_top_price1 span:last-child{
    color: #BFBFBF;
    margin-top: 3px;
    font-size: 20px;
    text-decoration:line-through;
}
.tlc_L img{ width: 100%; height: 100%;}
.tlc_star img{ width: 12px; height: 12px; float: left;}
.order_details_main .tabList_li_content{border-bottom: 1px solid  #F2F2F2;}
.odm_address_tel image{ width: 22px; height: 22px; position: relative; top: -2px;}
.odm_address .odm_address_font01{ display: flex; height: 45px;align-items: center;word-break:break-all; overflow: hidden;}

.odn_list_li span{ display: block; margin-bottom: 4px;}

.odi_buy span{ display: block; width: 100px; height: 30px; line-height: 30px; background: #FF4D4F;
    border-radius: 2px; text-align: center;font-size: 16px; color: #FFFFFF; margin: 0 auto;}


.order_details_pay01 span:first-child{
    font-size: 22px;
    color: #FF4D4F;
    text-shadow: 0 2px 4px rgba(255,77,79,0.24);
    margin-right: 15px;
}

.order_details_pay01 span:last-child{
    color: #BFBFBF;
    margin-top: 3px;
    font-size: 20px;
    text-decoration:line-through;
}
.code-wrapper {
    text-align: center;
}
.code-text {
    text-align: center;
    letter-spacing: 6px;
    font-size: 28px;
}
.qrcode-button {
    margin-top: 20px;
}
</style>