<template>
    <div class="merchant-block top-food">
        <div class="block-title">{{$t('menu')}}</div>
        <div class='menu-scroll-wrapper'>
            <div class="menu-scroll">
                <template v-for="(item, index) in menu">
                    <div class='item' @click='previewImg(index)' :key="index">
                        <img :src='item.storeMenuImg' mode='aspectFill' />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "en": {
        "menu": "Menu"
    },
    "zh_CN": {
        "menu": "菜单"
    }
}
</i18n>

<script>
import {ImagePreview} from "vant";

export default {
    name: "StoreMenu",
    props: {
        menu: Array
    },
    computed: {
        getImages() {
            return this.menu.map(img => {
                return img.storeMenuImg;
            })
        }
    },
    methods: {
        previewImg(index) {
            let self = this;
            ImagePreview({
                images: self.getImages,
                startPosition: index,
                closeable: true
            })
        }
    }
}
</script>

<style scoped>
.block-title {
    padding: 10px 16px;
    font-size: 20px;
    font-weight: 400;
}

.menu-scroll-wrapper {
    overflow: hidden;
    padding: 0 16px;
    margin-bottom: 16px;
}
.menu-scroll {
    overflow-x: scroll;
    white-space: nowrap;
}
.menu-scroll::-webkit-scrollbar {
    display: none;
}
.menu-scroll .item {
    display: inline-block;
    width: 130px;
    height: 76px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    margin-right: 12px;
}
.menu-scroll .item:last-child {
    margin-right: 0;
}
.item img {
    width: 100%;
    height: auto;
}

</style>