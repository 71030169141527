import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n';
import {localeKey, stripeKey} from "@/utils/config";

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
    config: { id: "G-VW2RPMHMFR"}
}, router);

import { StripePlugin } from "@vue-stripe/vue-stripe";

const options = {
    pk: stripeKey,
    testMode: true,
    apiVersion: "2022-11-15"
};
Vue.use(StripePlugin, options);

window.Bus = new Vue();
import {
    ActionSheet,
    Button,
    Card,
    Cell,
    CellGroup,
    Col,
    Collapse,
    CollapseItem,
    Dialog,
    Field,
    Form,
    Grid,
    GridItem,
    Icon,
    Image,
    ImagePreview,
    Lazyload,
    List,
    NavBar,
    Notify,
    PullRefresh,
    Radio,
    RadioGroup,
    Rate,
    Row,
    Search,
    Skeleton,
    Stepper,
    SubmitBar,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Tabbar,
    TabbarItem,
    Toast
} from "vant";

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(ActionSheet)
    .use(Button);
Vue.use(Card);
Vue.use(Cell);
Vue.use(CellGroup)
    .use(Col)
    .use(Collapse)
    .use(CollapseItem)
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Form)
    .use(Grid)
    .use(GridItem)
    .use(Icon)
    .use(Image)
    .use(ImagePreview)
    .use(Lazyload)
    .use(List)
    .use(NavBar)
    .use(Notify)
    .use(PullRefresh)
    .use(Radio)
    .use(RadioGroup)
    .use(Rate)
    .use(Row)
    .use(Search)
    .use(Skeleton);
Vue.use(Stepper);
Vue.use(SubmitBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);

const i18n = new VueI18n({
    locale: window.localStorage.getItem(localeKey) || 'en',
    messages: {
        en: {
            emailUpdate: 'Email Updated!',
            codeSent: 'Code has been sent',
            warning: 'Warning!',
            cancel: 'Cancel',
            confirm: 'Confirm',
            submit: 'Submit',
            power: {
                combinedable: "Can combined with other discount",
                notCombinedable: "Cannot combined with other discount",
                refundable: "Refundable",
                notRefundable: "Not refundable",
                refundableAfterExpired: "Refundable when expired",
                notRefundableAfterExpired: "Not refundable when expired"
            }
        },
        zh_CN: {
            emailUpdate: 'Email更新成功',
            codeSent: '验证码已发送',
            warning: '警告！',
            cancel: '取消',
            confirm: '确认',
            submit: '提交',
            power: {
                combinedable: "可叠加",
                notCombinedable: "不可叠加",
                refundable: "可退款",
                notRefundable: "不可退款",
                refundableAfterExpired: "过期可退",
                notRefundableAfterExpired: "过期不可退"
            }
        }
    }
});

const app = new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
});
store.$app = app;
app.$mount('#app');
