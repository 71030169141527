<template>
    <div class='coupon_deals' @click='goUse(item.id)'>
        <img :src='item.image' class='coupon_deals_left' />
        <div class='coupon_deals_right'>
            <div class='coupon_deals_title'>{{item.couponName}}</div>
            <div class='coupon_deals_time'>
                {{item.statusName}}
            </div>
        </div>
        <van-button round class='coupon_get' :disabled="disabled">使用</van-button>
    </div>
</template>

<script>
export default {
    name: "CouponItem",
    props: {
        item: Object,
        disabled: Boolean
    },
    methods: {
        goUse(id) {
            if (!this.disabled) {
                this.$router.push('/my/coupon/' + id);
            }
        }
    }
}
</script>

<style scoped>

.coupon_deals {
    padding: 16px;
    height: 68px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
}

.coupon_deals_left {
    width: 92px;
    height: 68px;
    border-radius: 7px;
    overflow: hidden;
    float: left;
    padding-right: 12px;
}

.coupon_deals_left img {
    width: 100%;
    height: 100%;
}

.coupon_deals_right {
    margin-left: 92px;
    line-height: 18px;
    padding-top: 6px;
}

.coupon_deals_title {
    margin-bottom: 11px;
}

.coupon_deals_time {
    margin-bottom: 6px;
    color: #919191;
}

.coupon_deals_price span {
    margin-right: 11px;
}

.coupon_deals .coupon_more {
    right: 26px;
}
.coupon_get {
    position: absolute;
    background-image: linear-gradient(-270deg, #F7393B 0%, #F96B3E 100%);
    border-radius: 12px;
    width: 74px;
    height: 23px;
    line-height: 24px;
    right:12px;
    text-align: center;
    color: #fff; top: 50%;
    transform: translateY(-50%);
}
</style>