<template>
    <van-cell-group class="coupon-list-wrapper">
        <div class='coupon_deals' @click='goCoupon(item.id)' v-for="item in dish" :key="item.id">
            <img :src='item.image' class='coupon_deals_left' />
            <div class='coupon_deals_right'>
                <div class='coupon_deals_title'>{{item.name}}</div>
                <div class='coupon_deals_time'>
                    <template v-for="(itm, idx) in item.powerDescription">
                        <span v-if="idx===0" :key="'itm1-' + idx">{{itm===0?'不可叠加 ':'可叠加 '}}|</span>
                        <span v-if="idx===1" :key="'itm2-' + idx">{{itm===0?' 不可退 ':' 随时退 '}}|</span>
                        <span v-if="idx===2" :key="'itm3-' + idx">{{itm===0?' 过期不可退':' 过期可退'}}</span>
                    </template>
                </div>
                <div class='coupon_deals_price'>
                    <span class='red'>${{item.couponData.coupon_value}}</span>
                    <span style="text-decoration:line-through;">${{item.couponData.face_value}}</span>
                </div>
            </div>
            <div class='coupon_get'>{{$t('detail')}}</div>
        </div>
    </van-cell-group>
</template>

<i18n>
{
    "en": {
        "detail": "Detail"
    },
    "zh_CN": {
        "detail": "查看详情"
    }
}
</i18n>
<script>
export default {
    name: "CouponList",
    props: {
        dish: Array,
        coupon: Array
    },
    methods: {
        goCoupon(id) {
            this.$router.push('/coupon/' + id);
        }
    }
}
</script>

<style scoped>
.coupon-list-wrapper {
    padding: 10px 16px;
    /*border-top: #9B9B9B solid 1px;*/
}
.coupon_deals {
    padding: 16px 16px 16px 0;
    height: 68px;
    overflow: hidden;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.coupon_deals_left {
    width: 92px;
    height: 100%;
    border-radius: 6px;
}

.coupon_deals_right {
    flex: 1;
    padding: 5px 8px;
}

.coupon_deals_title {
    margin-bottom: 11px;
}

.coupon_deals_time {
    margin-bottom: 6px;
    color: #919191;
}

.coupon_deals_price {
    font-size: 16px;
    color: #BFBFBF;
}

.coupon_deals_price span {
    margin-right: 11px;
}

.coupon_deals .coupon_more {
    right: 26px;
}
.coupon_get {
    display: block;
    background-image: linear-gradient(-270deg, #F7393B 0%, #F96B3E 100%);
    border-radius: 12px;
    width: 74px;
    height: 23px;
    line-height: 24px;
    text-align: center;
    color: #fff; top: 50%;
}
</style>