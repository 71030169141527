<template>
    <div class="merchant-block address_block">
        <div class="store_address">
            <van-cell-group>
                <van-cell
                    :title-class="'info-title'"
                    :value-class="'value'"
                    :value="info.distance + ' mi'|| ''" icon="location-o">
                    <template #title>
                        <span class="info-title">{{info.address}}</span>
                    </template>
                </van-cell>
                <van-cell :title="info.linkPhone || ''" icon="phone" value=""></van-cell>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddressInfo",
    props: {
        info: Object
    },
}
</script>

<style scoped>
.address_block {
    padding-top: 0;
}

.store_address span{
    /*font-size: 16px;*/
    color: #707070;
}
.store_address .info-title, .store_address .value {
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;
}
</style>