<template>
    <div>
        <transition name="van-slide-left">
            <router-view/>
        </transition>
        <van-tabbar route>
            <van-tabbar-item :to="{name: 'Home'}" icon="home-o" replace>{{$t('home')}}</van-tabbar-item>
            <van-tabbar-item to="/my" icon="user-o" replace>{{$t('me')}}</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<i18n>
{
    "en": {
        "home": "Home",
        "me": "Me"
    },
    "zh_CN": {
        "home": "主页",
        "me": "我的"
    }
}
</i18n>

<script>

import {BaseMixin} from "@/utils/mixin";

export default {
    name: "HomeLayout",
    mixins: [BaseMixin],
    data: () => ({
        active: 0
    }),
    computed: {
    }
}
</script>

<style scoped>

</style>