<template>
    <div class="home">
<!--        <HomeBanner/>-->
        <van-search
            v-model="search"
            shape="round"
            :placeholder="$t('placeholder')"
            @search="onSearch"
        />
        <HotCoupon/>
        <HotStoreList/>
    </div>
</template>

<i18n>
{
    "en": {
        "placeholder": "Please input key words"
    },
    "zh_CN": {
        "placeholder": "请输入搜索关键词"
    }
}
</i18n>

<script>
// @ is an alias to /src
// import HomeBanner from "@/components/Home/HomeBanner.vue";
import HotStoreList from "@/components/Home/HotStoreList.vue";
import HotCoupon from "@/components/Home/HotCoupon.vue";
import {pageMixin} from "@/utils/mixin";

export default {
    name: 'HomeView',
    mixins: [pageMixin],
    components: {
        // HomeBanner,
        HotStoreList,
        HotCoupon
    },
    data: ()=> ({
        isHomeLayout: true,
        search: ''
    }),
    mounted() {
        if (!this.$store.getters.isLocationLoaded) {
            this.$store.dispatch('getGeoLocation');
        }
        this.track();
    },
    methods: {
        onSearch(val) {
            console.log(val)
            if (val) {
                this.$store.commit('updateSearch', val);
                this.$router.push('/search');
            }
        }
    }
}
</script>
<style>
.home {
    height: calc(100vh - 96px);
    overflow-y: scroll;
}
.home-block-title {
    font-size: 22px;
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
}
</style>