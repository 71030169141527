<template>
    <div>
        <div class="user-section">
            <van-button
                type="info" v-if="!$store.getters.isUserLogin"
                to="/login"
            >Login</van-button>
            <template v-else>
                <p>{{$t('welcome')}} {{$store.getters.getUserMobile}}</p>
            </template>
        </div>
        <van-cell-group>
            <van-cell :title="$t('profile')" is-link to="/profile" />
<!--            <van-cell title="My Coupons" is-link to="/my/coupon" />-->
            <van-cell :title="$t('order')" is-link to="/orders" />
            <van-cell :title="$t('card')" is-link to="/my/card" />
            <van-cell :title="$t('terms')" is-link to="/terms" />
            <van-cell
                :title="$t('logout')"
                v-if="$store.getters.isUserLogin"
                title-class="logout-cell"
                clickable
                @click="$store.dispatch('userLogout')"
            />
        </van-cell-group>
    </div>
</template>

<i18n>
{
    "en": {
        "welcome": "Welcome!",
        "profile": "Profile",
        "order": "My Orders",
        "card": "My Cards",
        "terms": "Terms and Privacy",
        "logout": "Logout"
    },
    "zh_CN": {
        "welcome": "欢迎！",
        "profile": "账号信息",
        "order": "我的订单",
        "card": "我的银行卡",
        "terms": "条款和隐私",
        "logout": "退出登录"
    }
}
</i18n>


<script>
import {pageMixin} from "@/utils/mixin";

export default {
    name: "MyProfile",
    mixins: [pageMixin],
    mounted() {
        this.track();
    }
}
</script>

<style scoped>
    .user-section {
        text-align: center;
        padding: 30px;
    }
    .logout-cell {
        color: red;
    }
</style>