<template>
    <div class='container' :style="coupon.couponTypeCode=='YHQ'?'margin-bottom:0rpx':''">
        <div class='voucher_detail_top'>
            <img :src='coupon.image' class='voucher_detail_top_image' />
            <div class='voucher_detail_top_title'>{{coupon.name}}</div>
            <div class='voucher_detail_top_price' v-if="coupon.couponTypeCode !== 'YHQ'">
                <span class='gray'>{{$t('available', {start: coupon.startTime, end:coupon.endTime})}}</span>
            </div>
            <div class='voucher_detail_top_price1' v-if="coupon.couponTypeCode != 'YHQ'">
                <span v-if="coupon.couponData">${{coupon.couponData.coupon_value}}</span>
                <span v-if="coupon.couponData">${{coupon.couponData.face_value}}</span>
            </div>
            <div class='voucher_detail_top_tips' v-show="coupon.powerDescription!==''">
                <template v-for="(item, index) in coupon.powerDescription">
                    <span v-if="index===0" :key="'item1-'+index">{{item?$t('power.notCombinedable'):$t('power.combinedable')}}</span>
                    <span v-if="index===1" :key="'item2-'+index">{{item?$t('power.notRefundable'):$t('power.refundable')}}</span>
                    <span v-if="index===2" :key="'item3-'+index">{{item?$t('power.notRefundableAfterExpired'):$t('power.refundableAfterExpired')}}</span>
                </template>
            </div>
        </div>
        <div class='order_details_notes' v-if="coupon.couponTypeCode != 'YHQ'">
            <div class='odm_title'>{{$t('info')}}</div>
            <div class='odn_list'>
                <div v-html='coupon.description + coupon.remark' class='rich-text'></div>
            </div>
        </div>
        <div class='order_details_pay' v-if="coupon.couponTypeCode != 'YHQ'">
            <div class='order_details_pay01' v-if="coupon.couponData">
                <span>${{coupon.couponData.coupon_value}}</span>
                <span>${{coupon.couponData.face_value}}</span>
            </div>
            <div class='order_details_pay02' @click='buyCoupon'>{{ $t('buy') }}</div>
        </div>
    </div>

</template>

<i18n>
{
    "en": {
        "buy": "Buy",
        "info": "Information",
        "available": "Available from {start} to {end}."
    },
    "zh_CN": {
        "buy": "立即抢购",
        "info": "购买须知",
        "available": "有效期：{start} 至 {end}."
    }
}
</i18n>


<script>
import API from "@/utils/api";
import request from "@/utils/request";
import {formatDate, img2fullURL} from "@/utils/helper";
import {BaseMixin, pageMixin} from "@/utils/mixin";
export default {
    name: "CouponDetail",
    mixins: [BaseMixin, pageMixin],
    data: () => ({
        coupon: {}
    }),
    mounted() {
        this.track();
        if (this.$route.params.id) {
            this.loadCoupon(this.$route.params.id);
        }
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadCoupon(this.$route.params.id);
            }
        },
    },
    methods: {
        loadCoupon(id) {
            let self = this;
            request({
                ...API.COUPON_DETAIL,
                params: {
                    couponId: id
                }
            }).then(item => {
                item.image && (item.image = img2fullURL(item.image));
                if (item.couponData) {
                    if (typeof item.couponData === 'string')
                        item.couponData = JSON.parse(item.couponData);
                }
                if (item.powerDescription) {
                    if (typeof item.powerDescription === 'string')
                        item.powerDescription = item.powerDescription.split('|');
                }
                item.startTime && (item.startTime = formatDate(item.startTime));
                item.endTime && (item.endTime = formatDate(item.endTime));
                self.coupon = item;
            })
        },
        buyCoupon() {
            this.$store.commit('setCoupon', this.coupon);
            this.$router.push('/checkout');
        }
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 55px;
}
.gray{color: #919191;}
.voucher_detail_top{ padding: 11px 16px 0 16px; background: #fff; margin-bottom: 10px;}
.voucher_detail_top_image{ width: 100%; height: 175px; border-radius: 5px; margin-bottom: 10px;}
.voucher_detail_top_title{font-size: 18px;color: #333333; line-height: 20px;font-weight: bold;}
.voucher_detail_top_price{ position: relative;line-height:20px; height: 20px; padding: 13px 0; font-size: 16px; overflow:hidden; border-bottom: 2px solid #f2f2f2;}
.voucher_detail_top_price span{ display: block; float: left; position: relative;}
.voucher_detail_top_tips{font-size: 14px;color: #FF4D4F;line-height: 14px; padding:16px 0; height: 14px;  overflow: hidden;}
.voucher_detail_top_tips img{ float: left; margin-right: 5px; width: 14px; height: 14px;}
.voucher_detail_top_tips span{ float: left; margin-right: 20px;}
.tabList_li_content navigator{ overflow: hidden;}

.voucher_detail_top_price1{
    padding: 13px 0;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
}

.voucher_detail_top_price1 span:first-child{
    font-size: 20px;
    color: #FF4D4F;
    text-shadow: 0 2px 4px rgba(255,77,79,0.24);
    margin-right: 15px;
}

.voucher_detail_top_price1 span:last-child{
    color: #BFBFBF;
    margin-top: 3px;
    font-size: 20px;
    text-decoration:line-through;
}
.tlc_L img{ width: 100%; height: 100%;}
.tlc_star img{ width: 12px; height: 12px; float: left;}
.odm_title{ line-height: 35px; font-size: 16px;color: #333333; border-bottom: 1px solid  #F2F2F2; }
.odm_title{ line-height: 35px; font-size: 16px;color: #333333; border-bottom: 1px solid  #F2F2F2; }
.order_details_main .tabList_li_content{border-bottom: 1px solid  #F2F2F2;}
.odm_address_tel image{ width: 22px; height: 22px; position: relative; top: -2px;}
.odm_address .odm_address_font01{ display: flex; height: 45px;align-items: center;word-break:break-all; overflow: hidden;}
.order_details_notes{ background: #fff; margin-bottom: 10px; padding: 0 18px;}

.odn_list{ overflow: hidden;}
.odn_list_li span{ display: block; margin-bottom: 4px;}

.odi_buy span{ display: block; width: 100px; height: 30px; line-height: 30px; background: #FF4D4F;
    border-radius: 2px; text-align: center;font-size: 16px; color: #FFFFFF; margin: 0 auto;}

.order_details_pay{
    position: fixed;
    bottom: 0;
    left:0;
    right: 0;
    height: 50px;
    font-size: 20px;
    color: #9B9B9B;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_details_pay01{
    display: flex;
    margin-right: 125px;
    font-size: 18px;
    padding:18px 0 18px 30px;
    height: 18px;
    line-height: 18px;
    overflow:hidden;
}

.order_details_pay01 span:first-child{
    font-size: 22px;
    color: #FF4D4F;
    text-shadow: 0 2px 4px rgba(255,77,79,0.24);
    margin-right: 15px;
}

.order_details_pay01 span:last-child{
    color: #BFBFBF;
    margin-top: 3px;
    font-size: 20px;
    text-decoration:line-through;
}

.order_details_pay02{
    width: 125px;
    background-image: linear-gradient(-270deg, #FF4D4F 0%, #FD7D54 100%);
    text-align: center;
    line-height: 50px;
    color: white;
}

.rich-text {
    margin: 13px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    overflow: hidden;
    word-break:break-all;
    color: #333333;
}
</style>