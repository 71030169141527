import Vue from 'vue'
import Vuex from 'vuex'
import {Toast} from "vant";
import {TokenKey, version, localeKey} from "@/utils/config";
import {getCustomerLocation, setCustomerLocation, defaultPosition} from "@/utils/helper";
import router from "@/router";
import request from "@/utils/request";
import API from "@/utils/api";
import _ from "lodash";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: '',
        user: {
            id: null,
            mobile: null,
            email: null,
            locale: null
        },
        location: {
            latitude: '',
            longitude: ''
        },
        version: version,
        fullscreenLoading: false,
        coupon: {},
        city: {},
        pageTitle: '',
        search: ''
    },
    getters: {
        isUserLogin: (state) => {
            return !!state.user.mobile;
        },
        isLocationLoaded: (state) => {
            return (!!state.location.latitude) && (!!state.location.longitude);
        },
        getCoupon: (state) => {
            return state.coupon;
        },
        getUserMobile: (state) => {
            return state.user.mobile;
        },
        getUserEmail: (state) => {
            return state.user.email
        },
        userHaveEmail: (state) => {
            return !!state.user.email;
        },
        getPageTitle: (state) => {
            return state.pageTitle;
        },
        isLocationAndCityLoaded: (state) => {
            return (!!state.location.latitude) && (!!state.location.longitude) && (!!state.city.id);
        },
        getLocationName: (state) => {
            return state.city.name;
        },
        getLocale: (state) => {
            return state.user.locale ?? 'en';
        }
    },
    mutations: {
        updatePosition: (state, payload) => {
            state.location.latitude = payload.latitude;
            state.location.longitude = payload.longitude;
            setCustomerLocation(payload);
        },
        clearPosition:(state) => {
            state.location = {
                latitude: '',
                longitude: ''
            }
        },
        updateFullscreenLoading(state, payload) {
            if (payload) {
                Toast.loading({
                    message: 'Loading...',
                    duration: 0,
                    forbidClick: true
                })
            } else {
                Toast.clear()
            }
        },
        setCoupon:(state, payload) => {
            state.coupon = payload;
            window.localStorage.setItem('coupon', JSON.stringify(state.coupon));
        },
        userLoginSuccess: (state, payload) => {
            state.user.id = payload.userId;
            state.user.mobile = payload.mobile;
            state.token = payload.token;
            window.localStorage.setItem(TokenKey, payload.token);
            Toast.success('Login successfully!');
            console.log('登录成功');
        },
        updateUser(state, payload) {
            state.token = window.localStorage.getItem(TokenKey);
            state.user.mobile = payload.mobile;
            state.user.id = payload.id;
            state.user.email = payload.email;
        },
        userReset(state) {
            window.localStorage.removeItem(TokenKey);
            state.user.id = null;
            state.user.mobile = null;
            state.token = '';
        },
        updateCity(state, payload) {
            state.city = payload;
        },
        updatePageTitle(state, payload) {
            state.pageTitle = payload;
        },
        updateSearch(state, payload) {
            state.search = payload;
        },
        updateLocale(state, payload) {
            state.user.locale = payload;
            this.$app.$i18n.locale = payload;
            window.localStorage.setItem(localeKey, payload);
        }
    },
    actions: {
        getGeoLocation: ({ commit,getters, dispatch }, payload) => {
            let customerLocation = {};
            if (payload && payload.reload) {
                customerLocation = {};
            } else {
                if (getters.isLocationLoaded)
                    return;
                customerLocation = getCustomerLocation();
            }
            if (!_.isEmpty(customerLocation)) {
                commit('updatePosition', customerLocation);
                dispatch('getCityByLatLng');
            } else if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    console.log('location: ', position.coords);
                    commit('updatePosition', position.coords);
                    dispatch('getCityByLatLng');
                }, (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.log("User denied the request for Geolocation.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.log("Location information is unavailable.")
                            break;
                        case error.TIMEOUT:
                            console.log("The request to get user location timed out.")
                            break;
                        default:
                            console.log("An unknown error occurred.")
                            break;
                    }
                    commit('updatePosition', defaultPosition);
                    dispatch('getCityByLatLng');
                });
            } else {
                const msg = "Geolocation is not supported by this browser.";
                console.log(msg);
                window.Bus.$notify({ type:'warning', message: msg });
                commit('updatePosition', defaultPosition);
                dispatch('getCityByLatLng');
            }
        },
        getCityByLatLng: ({ getters, state, commit }) => {
            if ( getters.isLocationLoaded ) {
                request({
                    ...API.GET_CITY_BY_LATLNG,
                    params: {
                        ...state.location,
                        top: true
                    }
                }).then(res => {
                    commit('updateCity', res);
                })
            }
        },
        userLogout({ commit }) {
            commit('userReset');
            router.replace('/');
        },
        loadUserInfo({ state, commit, getters }) {
            let token = '';
            if (state.token) {
                token = state.token;
            } else {
                token = window.localStorage.getItem(TokenKey);
            }
            if (!state.user.locale) {
                if (window.localStorage.getItem(localeKey)) {
                    commit('updateLocale', window.localStorage.getItem(localeKey));
                }
            }
            if (token && !getters.isUserLogin) {
                request({
                    ...API.USER_ME
                }).then(res => {
                    commit('updateUser', res);
                });
            }
        }
    },
    modules: {}
})
