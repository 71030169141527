<template>
    <van-tabs v-model="activeType" @change="updateOrders">
        <van-tab
            v-for="type in typeList"
            :key="type.status"
            :title="$t(type.name)"
            :name="type.status"
        >
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh(type.status)">
                <van-list
                    v-model="loading"
                    @load="loadMyOrders(type.status, searchParams.pageNum+1)"
                    :finished="finished"
                    :finished-text="$t('finished')"
                >
                    <div class="container">
                        <OrderItem v-for="item in orders" :item="item" :key="item.id" />
                    </div>
                </van-list>
            </van-pull-refresh>
        </van-tab>
    </van-tabs>
</template>

<i18n>
{
    "en": {
        "finished": "No more orders.",
        "all": "All",
        "pending": "Pending",
        "available": "Available",
        "canceled": "Canceled"
    },
    "zh_CN": {
        "finished": "没有更多",
        "all": "全部订单",
        "pending": "待付款",
        "available": "可使用",
        "canceled": "已取消"
    }
}
</i18n>

<script>
import {BaseMixin} from "@/utils/mixin";
import request from "@/utils/request";
import API from "@/utils/api";
import {formatDate, img2fullURL} from "@/utils/helper";
import OrderItem from "@/components/My/OrderItem.vue";
// import _ from "lodash";

export default {
    name: "OrderList",
    components: {OrderItem},
    mixins: [BaseMixin],
    data: () => ({
        orders: [],
        couponsA: [],
        couponsNA: [],
        activeType: 9,
        isLoading: false,
        typeList: [
            { name: 'all', status: 9 },
            { name: 'pending', status: 0 },
            { name: 'available', status: 1 },
            { name: 'canceled', status: 2 },
        ],
        searchParams: {
            pageNum: 1,
            pageSize: 10,
            statusType: 0
        },
        loading: false,
        finished: false
    }),
    mounted() {
        this.loadMyOrders(9);
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadMyOrders(9);
            }
        },
    },
    methods: {
        loadMyOrders(type, page = 0) {
            let self = this;
            if (self.isLoading) {
                self.orders = [];
                self.isLoading = false;
            }
            self.searchParams.statusType = type;
            if (page > 0) {
                self.searchParams.pageNum = page;
            }
            request({
                ...API.ORDER_LIST,
                params: {...self.searchParams}
            }).then(res => {
                console.log(res);
                let orders = [];
                res.list.forEach(item => {
                    item.image && (item.image = img2fullURL(item.image));
                    item.expireTime && (item.expireTime = formatDate(item.expireTime));
                    // item.orderTime && (item.orderTime = formatDate(item.orderTime));
                    item.endTime = formatDate(item.couponDetail.endTime);
                    orders.push(item);
                });
                if (self.searchParams.pageNum > 1) {
                    self.orders = self.orders.concat(orders);
                } else {
                    self.orders = orders;
                }
                self.loading = false;
                if (res.list.length < 10) {
                    self.finished = true;
                }
            });
        },
        updateOrders(e) {
            this.loadMyOrders(e, 1);
        },
        onRefresh(type) {
            this.finished = false;
            this.loading = true;
            this.loadMyOrders(type, 1);
        }
    }
}
</script>

<style scoped>

</style>