import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import {getToken} from "@/utils/helper";
import request from "@/utils/request";
import API from "@/utils/api";
import StoreDetail from "@/views/StoreDetail.vue";
import CouponDetail from "@/views/CouponDetail.vue";
import Checkout from "@/views/Checkout.vue";
import CheckoutSuccess from "@/views/CheckoutSuccess.vue";
import Login from "@/views/Login.vue";
import MyCoupon from "@/views/MyCoupon.vue";
import My from "@/views/My.vue";
import CouponUse from "@/views/CouponUse.vue";
import HomeLayout from "@/views/HomeLayout.vue";
import ChooseLocation from "@/views/ChooseLocation.vue";
import Profile from "@/views/Profile.vue";
import EmailUpdate from "@/views/EmailUpdate.vue";
import Payment from "@/views/Payment.vue";
import OrderPayment from "@/views/OrderPayment.vue";
import OrderList from "@/views/OrderList.vue";
import OrderDetail from "@/views/OrderDetail.vue";
import SearchResult from "@/views/SearchResult.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: HomeLayout,
        children: [
            { path: '', component: HomeView, name: 'Home', meta: { locale: {en: 'Home', zh_CN: "主页"} } },
            { path: '/my', component: My, name: 'My', meta: { locale: {en: 'My', zh_CN: "我的"} } },
        ]
    },
    { path: '/store/:id', component: StoreDetail, name: 'Store', meta: {locale: {en: 'Store Detail', zh_CN: "商家详情"}} },
    { path: '/coupon/:id', component: CouponDetail, name: 'Coupon', meta: {locale: {en: 'Coupon Detail', zh_CN: "优惠详情"}} },
    { path: '/checkout', component: Checkout, name: 'Checkout', meta: {requireAuth: true, locale: {en: 'Checkout', zh_CN: "下单"}}},
    { path: '/order/:id/payment', component: OrderPayment, name: 'OrderPayment', meta: { requireAuth: true, locale: {en: 'Order Payment', zh_CN: "支付订单"} }},
    { path: '/checkout/success', component: CheckoutSuccess, name: 'Checkout Success', meta: { locale: {en: 'Thank You', zh_CN: "支付成功"} }},
    { path: '/location', component: ChooseLocation, name: 'Choose Location', meta: { locale: {en: 'Location', zh_CN: "区域选择"} } },
    { path: '/search', component: SearchResult, name: 'Search Result', meta: { locale: {en: 'Search Result', zh_CN: "搜索结果"} } },
    { path: '/profile', component: Profile, name: 'Profile', meta: { requireAuth: true, locale: {en: 'Profile', zh_CN: "个人详情"} } },
    { path: '/email-update', component: EmailUpdate, name: 'Update Email', meta: { requireAuth: true, locale: {en: 'Update Email', zh_CN: "更新邮箱"}} },
    { path: '/orders', component: OrderList, name: 'Order List', meta: { requireAuth: true, locale: {en: 'My Orders', zh_CN: "我的订单"}}},
    { path: '/order/:id', component: OrderDetail, name: 'Order Detail', meta: { requireAuth: true, locale: {en: 'Order Detail', zh_CN: "订单详情"} }},
    { path: '/my/coupon', component: MyCoupon, name: 'My Coupon', meta: {requireAuth: true, locale: {en: 'My Coupon', zh_CN: "我的优惠券"}}},
    { path: '/my/coupon/:id', component: CouponUse, name: 'Coupon Use', meta: {requireAuth: true, locale: {en: 'Use Coupon', zh_CN: "使用优惠券"}} },
    { path: '/my/card', component: Payment, name: 'My Payments', meta: { requireAuth: true, locale: {en: 'My Cards', zh_CN: "银行卡管理"}}},
    { path: '/login', component: Login, name: 'Login', meta: { locale: {en: 'Login', zh_CN: "登录"} } }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!getToken(true)) {
            router.push({
                name: 'Login',
                query: {redirect: to.fullPath}
            })
        } else {
            if (store.getters.isUserLogin) {
                next();
            } else {
                request({
                    ...API.USER_ME
                }).then(res => {
                    store.commit('updateUser', res);
                    next();
                });
            }
        }
    } else {
        next();
    }
})

export default router
