<template>
    <div>
        <stripe-element-card
            ref="paymentRef"
            :pk="pk"
            @token="tokenCreated"
        />
        <van-button type="primary" @click="submit" block>{{ $t('submit') }}</van-button>
    </div>
</template>

<script>
import {StripeElementCard} from "@vue-stripe/vue-stripe";
import {stripeKey} from "@/utils/config";
import request from "@/utils/request";
import API from "@/utils/api";

export default {
    name: "AddNewCard",
    components: {StripeElementCard},
    data: () => ({
        pk: stripeKey,
        token: null
    }),
    methods: {
        submit() {
            this.$refs.paymentRef.submit()
        },
        tokenCreated (token) {
            let self = this;
            request({
                ...API.CARD_SAVE,
                data: {
                    card_token: token.id
                }
            }).then(res => {
                console.log(res);
                self.$emit('card-added', res);
            })
        }
    }
}
</script>

<style scoped>

</style>