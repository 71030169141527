<template>
    <van-nav-bar
        :title="pageTitle"
        :left-arrow="!isHomeLayout && showArrow"
        @click-left="clickLeft"
        @click-right="clickRight"
    >
        <template #left v-if="isHomeLayout">
            <van-icon name="location-o" size="18"/>
        </template>
        <template #right>
            <span class="language-button">{{$t('language')}}</span>
        </template>
    </van-nav-bar>
</template>

<i18n>
{
    "en": {
        "language": "EN"
    },
    "zh_CN": {
        "language": "中"
    }
}
</i18n>
<script>

import {BaseMixin} from "@/utils/mixin";

export default {
    name: "SiteHeader",
    mixins: [BaseMixin],
    data: () => ({
        location: ''
    }),
    mounted() {
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$store.commit('updatePageTitle', this.$route.meta.locale[this.$i18n.locale]);
            }
        },
    },
    computed: {
        cityName() {
            if (this.$i18n === 'zh_CN') {
                return this.$store.state.city.name || '载入中...';
            } else {
                return this.$store.state.city.enlishCityName || 'Loading...';
            }
        },
        pageTitle() {
            if (this.isHomeLayout) {
                return this.cityName;
            } else {
                return this.$store.getters.getPageTitle;
            }
        },
        isHomeLayout() {
            return this.$route.path === '/'
                || this.$route.path === '/my';
        },
        showArrow() {
            return !(this.$route.path === '/checkout/success');
        }
    },
    methods: {
        clickLeft() {
            if (this.isHomeLayout) {
                this.$router.push('/location');
            } else {
                this.goBack();
            }
        },
        clickRight() {
            if (this.$i18n.locale === 'en') {
                this.$store.commit('updateLocale', 'zh_CN');
            } else {
                this.$store.commit('updateLocale', 'en');
            }
        }
    }
}
</script>

<style scoped>
.language-button {
    color: #1989fa;
}
</style>