let API = {};

// --- 用户.user ---
API.USER_LOGIN = {url: '/app/login', loading: true, method: 'post'}
API.USER_ME = {url: '/my/find', private: true}
API.SEND_CODE = { url: '/app/sendCode', loading: true, method: 'post' }
API.USER_SIGNUP = { url: '/app/reg', loading: true, method: 'post' }
API.UPDATE_EMAIL = { url: '/my/profile/email', loading: true, method: 'put', private: true }
API.EMAIL_CODE = { url: '/my/profile/email/code', loading: true, method: 'post', private: true}
API.FORGET_PASSWORD = { url: '/h5/forgetPassword', loading: true, method: 'post' }
API.FORGET_CODE = { url: '/h5/forgetPassword/code', loading: true, method: 'post' }

// --- 首页.index ---
API.POSTER_LIST = {}
API.POSTER_CAROUSEL = {url: '/carousel'}
API.GET_CITYCODE = {}
API.GET_CITY_BY_LATLNG = { url: '/city/byLatLng' };
API.HOT_MERCHANT = { url: '/store/rice/new', method: 'post', loading: true }
API.HOT_COUPON = { url: '/coupon/rice/home', method: 'post' }
API.SEARCH = { url: '/store/listPage', loading: true }

// --- 餐厅.store ---
API.STORE_INFO = {url: '/store/find', loading: true }
API.STORE_COUPON = {url: '/store/coupon/listPage', method: 'post'}
API.STORE_TOP_FOOD = {url: '/storeDish/list', method: 'post'}
API.STORE_MENU = {url: '/storeMenu/list', method: 'post'}

// --- 优惠券.coupon ---
API.COUPON_DETAIL = { url: '/store/coupon/get', loading: true }
API.COUPON_MINE = { url: '/my/coupon/list', private: true, method: 'post' }
API.COUPON_USE = { url: '/my/coupon/use', private: true, method: 'post', loading: true }
API.COUPON_CONFIRM = id => ({ url: '/coupon/use/' + id, private: true, method: 'post', loading: true});

// --- 订单.order ---
API.ORDER_FIND = {url: '/order/find', private: true }
API.ORDER_LIST = { url: '/payment/order/listPage', loading: true, private: true }
API.ORDER_DETAIL = { url: '/payment/order/find', loading: true, private: true }
API.ORDER_PLACE = { url: '/order/h5/place', method: 'post', private: true, loading: true }
API.ORDER_PAYMENT = { url: '/order/h5/payment', method: 'post', private: true, loading: true }
API.ORDER_APPLE_PAY = { url: '/order/h5/applePay', method: 'post', private: true, loading: true }
API.ORDER_CANCEL = { url: '/order/cancel', method: 'post', private: true, loading: true }
API.ORDER_REFUND = {}

// --- 支付.payment ---
API.PAYMENT_INTENT = { url: '/payment/intent', method: 'post', private: true }
API.MY_CARDS = { url: '/my/cards', private: true, loading: true }
API.CARD_SAVE = { url: '/my/card', method: 'post', private: true, loading: true }
API.CARD_DETELE = { url: '/my/card', method: 'delete', private: true, loading: true }

// --- 位置.location ---
API.CITY_LIST = { url: '/city/list', loading: true }
API.CITY_HOT_LIST = {}

export default API;