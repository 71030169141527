<template>
    <div class="container">
        <div class="home-block-title">{{$t('title', {target: $store.state.search})}}</div>
        <van-list>
            <div class="rice-list"
                 @click='toStore(item.id)'
                 v-for="item in merchants"
                 :key="item.id"
            >
                <div class="list-image">
                    <img :src="item.recommdMapImg" />
                </div>
                <div class="list-content">
                    <div class="content-main">
                        <div class="main-title">{{item.name}}</div>
                        <div class="main-labels">
                            <template v-for="(label, idx) in item.labels">
                                <span v-if="label.name === '小分队特别推荐'" :key="'label1-'+idx">吃货推荐</span>
                                <span v-else-if="label.name !== ''" :key="'label2-'+idx">{{label.name}}</span>
                                <span v-if="idx < (item.labels.length-2)" :key="'label3-'+idx"> · </span>
                            </template>
                        </div>
                        <div class="main-benefit">
                            <template v-for="benefit in item.benefits">
                                <div v-if="benefit.typeId === 1" class="benefit-discount" :key="benefit.id">
                                    {{ (  benefit.discount && benefit.discount
                                    < 100) ? benefit.discountText : benefit.type.show }}</div>
                                <div v-else class="benefit-gift" :key="benefit.id"> {{ benefit.type.show }} </div>
                            </template>
                        </div>
                        <div class="main-remark" v-if="item.remarkIntro">“{{item.remarkIntro}}”</div>
                    </div>
                    <div class="content-sub">
                        <div class="sub-distance">{{item.distance}} mi</div>
                        <div class="sub-price">
                            <span class="fontActive">{{calPrice(item.personPrice).active}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<i18n>
{
    "en": {
        "title": "Search result for '{target}'"
    },
    "zh_CN": {
        "title": "'{target}'的相关搜索结果"
    }
}
</i18n>

<script>
import {BaseMixin, pageMixin} from "@/utils/mixin";
import request from "@/utils/request";
import API from "@/utils/api";
import {formatTime, getDistance, img2fullURL} from "@/utils/helper";

export default {
    name: "SearchResult",
    mixins: [BaseMixin, pageMixin],
    data: () => ({
        merchants: []
    }),
    mounted() {
        this.track();
        if (this.$store.state.search) {
            this.loadMerchant(this.$store.state.search);
        }
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadMerchant(this.$store.state.search);
            }
        },
    },
    methods: {
        loadMerchant(search) {
            let self = this;
            request({
                ...API.SEARCH,
                params: {
                    pageNum: 1,
                    pageSize: 10,
                    latitude: self.$store.state.location.latitude,
                    longitude: self.$store.state.location.longitude,
                    name: search,
                    cityCode: self.$store.state.city.cityCode,
                    onlyLeague: 1
                }
            }).then(res => {
                let list = res.list;
                list.forEach(item => {
                    !item.name && (item.name = '');
                    !item.nameEnglish && (item.nameEnglish = '');
                    item.fullName = `${item.name} ${item.nameEnglish}`;
                    item.masterMapImg && (item.masterMapImg = img2fullURL(item.masterMapImg));
                    item.recommdMapImg && (item.recommdMapImg = img2fullURL(item.recommdMapImg));
                    item.createTime = formatTime(item.createTime);
                    // item.label && (item.label = item.label.split('|'));
                    // item.labels && (item.labels = item.labels.slice(0, 3));
                    item.label = item.labels.map(label => {
                        return label.name
                    }).join('·')
                    item.distance = getDistance(
                        self.$store.state.location.latitude,
                        self.$store.state.location.longitude,
                        item.addressLatitude,
                        item.addressLongitude
                    )
                });
                list.forEach(merchant => {
                    let resBene = []
                    let benefits = merchant.benefits
                    let hasType = false

                    // typeId 为 1的 折扣优惠， 筛选出最低的显示； typeId 不为1  的赠菜优惠，最多只显示一次
                    let discountBenefits = benefits.filter(benefit => {
                        return benefit.typeId === 1;
                    })
                    let sortedBenefits = discountBenefits.sort((b1, b2) => {
                        return b1.discount - b2.discount;
                    })

                    if (sortedBenefits.length > 0) {
                        resBene = [sortedBenefits[0]]
                    }

                    benefits.forEach(benefit => {
                        if (benefit.typeId == 2 && !hasType) {
                            resBene.push(benefit)
                            hasType = true
                        }
                    })

                    hasType = false

                    benefits.forEach(benefit => {
                        if (benefit.typeId == 3 && !hasType) {
                            resBene.push(benefit)
                            hasType = true
                        }
                    })

                    hasType = false

                    benefits.forEach(benefit => {
                        if (benefit.typeId == 4 && !hasType) {
                            resBene.push(benefit)
                            hasType = true
                        }
                    })

                    merchant.benefits = resBene
                    merchant.benefitLabel = resBene.map(bene => {
                        if (bene.typeId == 1) {
                            return bene.discountText
                        }else {
                            return bene.type.show
                        }
                    }).join('·')
                });
                self.merchants = [...list];
            })
        },
        toStore(id) {
            this.$router.push('/store/' + id);
        }
    }
}
</script>

<style scoped>
.new-store {
    padding: 0 10px;
}
.rice-list {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 13px 0;
    height: 100px;
}
.rice-list .list-image img {
    width: 100px;
    border-radius: 11px;
}
.rice-list .list-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0 6px 0 11px;
}
.list-content .content-main {
    line-height: 25px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-main .main-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
}
.content-main .main-benefit view{
    margin-right: 5px;
    padding: 0 5px;
    color: white;
    display: inline-block;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
}
.main-benefit .benefit-discount {
    background: #F7B500;
}
.main-benefit .benefit-gift {
    background: #F78253;
}
.content-main .main-labels {
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    text-overflow:ellipsis;
}
.content-main .main-remark {
    font-size: 14px;
    font-weight: 300;
    color: grey;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.list-content .content-sub {
    text-align: right;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    flex: 1;
    color: grey;
}

</style>