<template>
    <div class='container'>
        <van-cell-group>
            <van-cell v-if="order.store" :title="order.store.name" is-link :to="'/store/' + order.merchantNo"></van-cell>
            <div v-if="coupon" class="coupon-info" @click="goCoupon">
                <div class="coupon-info-l">
                    <img :src="coupon.image" alt="" class="coupon-img">
                </div>
                <div class="coupon-info-c">
                    <div class="coupon-info-c-title">
                        {{ coupon.name }}
                    </div>
                    <div class='coupon-info-c-time'>
                        <template v-for="(itm, idx) in coupon.powerDescription">
                            <span v-if="idx===0" :key="'itm1-' + idx">{{itm===0?'不可叠加 ':'可叠加 '}}|</span>
                            <span v-if="idx===1" :key="'itm2-' + idx">{{itm===0?' 不可退 ':' 随时退 '}}|</span>
                            <span v-if="idx===2" :key="'itm3-' + idx">{{itm===0?' 过期不可退':' 过期可退'}}</span>
                        </template>
                    </div>

                    <div class="coupon-value" v-if="coupon.couponData">
                        <span class="coupon-value-r">$ {{coupon.couponData.coupon_value}}</span>
                    </div>
                </div>
            </div>
        </van-cell-group>

        <van-cell-group>
            <van-cell
                v-for="(coupon, index) in customerCoupons"
                :title="coupon.couponCode"
                :key="index"
                :title-class="getTitleStyle(coupon.status)"
            >
                <template #right-icon>
                    <van-button
                        type="info"
                        size="mini"
                        :disabled="coupon.status !== '7'"
                        @click="scanCode(index)"
                    >{{showScanner? $t('closeCamera'): $t('scan')}}</van-button>
                </template>
            </van-cell>

            <StreamBarcodeReader
                v-if="showScanner"
                @decode="onDecode"
                @loaded="onLoaded"
                @error="onError"
            ></StreamBarcodeReader>
<!--            <div class="code-wrapper" v-if="canUse">-->
<!--                <qrcode-vue-->
<!--                    v-if="!showScanner" -->
<!--                    :value="getCodeUrl" -->
<!--                    size="260" -->
<!--                    class="code-qrcode"-->
<!--                ></qrcode-vue>-->
<!--                <p class="code-text">{{this.customerCoupons.couponCode}}</p>-->
<!--            </div>-->
        </van-cell-group>

        <van-cell-group>
            <div class="order-info">
                <h3>{{$t("orderInfo")}}</h3>
                <table>
                    <tr>
                        <td>{{$t("status")}}</td>
                        <td>{{getOrderStatus(order.status, $i18n.locale)}}</td>
                    </tr>
                    <tr>
                        <td>{{$t("total")}}</td>
                        <td>${{order.orderAmount}}</td>
                    </tr>
                    <tr>
                        <td>{{$t("quantity")}}</td>
                        <td>{{order.quantity}}</td>
                    </tr>
                    <tr>
                        <td>{{$t("orderNumber")}}</td>
                        <td>{{order.orderId}}</td>
                    </tr>
                    <tr>
                        <td>{{$t("orderTime")}}</td>
                        <td>{{order.orderTime}}</td>
                    </tr>
                </table>
            </div>
        </van-cell-group>

        <div class='order_details_pay'>
            <van-button
                v-if="order.status === 1 && customerCoupons.length === 1"
                class="qrcode-button" round type="info" @click="scanCode(0)" block>
                {{showScanner? $t('closeCamera'): $t('scan')}}
            </van-button>
            <van-button
                v-else-if="order.status === 0"
                class="qrcode-button" round type="info" block
                :to="'/order/' + $route.params.id + '/payment'"
            >
                Pay This Order
            </van-button>
            <van-button
                v-else
                class="qrcode-button" round type="info" block
                :to="'/coupon/' + coupon.id"
            >
                {{$t('buyAgain')}}
            </van-button>
        </div>
    </div>
</template>

<i18n>
{
    "en": {
        "buyAgain": "Buy Again",
        "pay": "Pay This Order",
        "closeCamera": "Close Camera",
        "scan": "Scan QR Code",
        "orderInfo": "Order Information",
        "status": "Status:",
        "total": "Total:",
        "quantity": "Quantity:",
        "orderNumber": "Order #:",
        "orderTime": "Order Time:",
        "loadCamera": "Loading camera..."
    },
    "zh_CN": {
        "buyAgain": "再次购买",
        "pay": "支付此订单",
        "closeCamera": "关闭摄像头",
        "scan": "扫描二维码",
        "orderInfo": "订单详情",
        "status": "订单状态:",
        "total": "总计金额：",
        "quantity": "购买数量：",
        "orderNumber": "订单号码:",
        "orderTime": "下单时间:",
        "loadCamera": "打开摄像头..."
    }
}
</i18n>

<script>
import request from "@/utils/request";
import API from "@/utils/api";
import {formatDate, img2fullURL} from "@/utils/helper";
import {StreamBarcodeReader} from "vue-barcode-reader";
// import QrcodeVue from "qrcode.vue";
import {qrcodeBaseUrl} from "@/utils/config";
import {BaseMixin, pageMixin} from "@/utils/mixin";

export default {
    name: "OrderDetail",
    mixins: [BaseMixin, pageMixin],
    components: { StreamBarcodeReader},
    data: () => ({
        id: 0,
        order: {},
        coupon: {},
        showScanner: false,
        customerCoupons: [],
        targetIndex: 0
    }),
    mounted() {
        this.track();
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.loadOrder();
        }
    },
    computed: {
        canUse() {
            return this.order.customerCoupons && this.order.status === 1
        },
        getCodeUrl() {
            return qrcodeBaseUrl + '?code=' + this.customerCoupons.couponCode;
        }
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadOrder();
            }
        },
    },
    methods: {
        loadOrder() {
            let self = this;
            request({
                ...API.ORDER_DETAIL,
                params: {
                    orderId: self.id
                }
            }).then(res => {
                let item = res.coupon;
                item.image && (item.image = img2fullURL(item.image));
                if (item.couponData) {
                    if (typeof item.couponData === 'string')
                        item.couponData = JSON.parse(item.couponData);
                }
                if (item.powerDescription) {
                    if (typeof item.powerDescription === 'string')
                        item.powerDescription = item.powerDescription.split('|');
                }
                item.startTime && (item.startTime = formatDate(item.startTime));
                item.endTime && (item.endTime = formatDate(item.endTime));
                self.order = res;
                self.coupon = item;
                self.customerCoupons = res.customerCoupons;
            })
        },
        goCoupon() {
            this.$router.push('/coupon/' + this.coupon.id);
        },
        onDecode(decodedString) {
            console.log(decodedString);
            let self = this;
            self.$dialog.confirm({
                title: 'Confirmation',
                message: 'Are you sure to use this coupon?'
            }).then(() => {
                request({
                    ...API.COUPON_CONFIRM(self.customerCoupons[self.targetIndex].id),
                    data: {
                        store: decodedString
                    }
                }).then(() => {
                    self.$toast.success('Success');
                    self.loadOrder();
                    self.showScanner = false;
                })
            })
        },
        getTitleStyle(status) {
            if (status === '7') {
                return 'code-available'
            } else {
                return 'code-disabled';
            }
        },
        scanCode(index) {
            let self = this;
            if (this.showScanner) {
                this.showScanner = false;
            } else {
                this.targetIndex = index;
                this.showScanner = true;
                this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: self.$t('loadCamera'),
                    loadingType: 'spinner'
                });
            }
        },
        onLoaded() {
            this.$toast.clear();
        },
        onError(e) {
            this.$toast.fail(e);
        }
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 55px;
}
.tabList_li_content navigator{ overflow: hidden;}
.coupon-info {
    display: flex;
    padding: 5px;
    height: 68px;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px 16px;
}
.coupon-info-l {
    width: 20%;
    height: 100%;
}
.coupon-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}
.coupon-info-c {
    flex: 1;
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.coupon-info-c-time {
    font-size: 12px;
    font-weight: 300;
    flex-grow: 1;
}

.coupon-value-r {
    font-size: 14px;
}

.order_details_pay{
    position: fixed;
    bottom: 0;
    left:0;
    right: 0;
    padding: 5px 10px;
    height: 50px;
    font-size: 20px;
    color: #9B9B9B;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.code-wrapper {
    text-align: center;
}
.code-text {
    text-align: center;
    letter-spacing: 6px;
    font-size: 28px;
}
.order-info {
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}
.order-info h3 {
    margin: 0 0 10px 0;
}
.code-available {
    letter-spacing: 2px;
    font-size: 22px;
    font-weight: 600;
}
.code-disabled {
    text-decoration: line-through;
    letter-spacing: 2px;
    font-size: 22px;
    font-weight: 600;
}
</style>