<template>
    <div class="merchant-block top-food">
        <div class="block-title">{{$t('recommendation')}}</div>
        <div class="top-scroll-wrapper">
            <div class='top-scroll' style="width: 100%">
                <template v-for="(item, index) in topFood">
                    <div
                        class='item'
                        :key="index"
                        @click='previewImg(index)'
                    >
                        <img :src='item.storeDishImg' mode='aspectFill' />
                        <div class='text' v-if="item.storeDishText">{{item.storeDishText}}</div>
                    </div>
                </template>
            </div>
        </div>
        <van-image-preview v-model="showPreview" :images="getImages" @change="onChange" :closeable="true">
            <template #index>{{topFood[imageIdx].storeDishText}}</template>
        </van-image-preview>
    </div>
</template>

<i18n>
{
    "en": {
        "recommendation": "Hot Dishes"
    },
    "zh_CN": {
        "recommendation": "推荐菜"
    }
}
</i18n>

<script>
// import {ImagePreview} from "vant";

export default {
    name: "StoreDish",
    props: {
        topFood: Array
    },
    data: () => ({
        showPreview: false,
        imageIdx: 0
    }),
    computed: {
        getImages() {
            return this.topFood.map(img => {
                return img.storeDishImg;
            })
        }
    },
    methods: {
        previewImg() {
            // let self = this;
            // ImagePreview({
            //     images: self.getImages,
            //     startPosition: index,
            //     closeable: true
            // })
            this.showPreview = true
        },
        onChange(index) {
            this.imageIdx = index
        }
    }
}
</script>

<style scoped>

.block-title {
    padding: 10px 16px;
    font-size: 20px;
    font-weight: 400;
}

.top-scroll-wrapper {
    overflow: hidden;
    padding: 0 16px;
    margin-bottom: 16px;
}
.top-scroll {
    overflow-x: scroll;
    white-space: nowrap;
}
.top-scroll::-webkit-scrollbar {
    display: none;
}
.top-scroll .item {
    display: inline-block;
    width: 130px;
    height: 76px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    margin-right: 12px;
}
.top-scroll .item:last-child {
    margin-right: 0;
}
.item img {
    width: 100%;
    height: auto;
}
.item .text {
    width: 100%;
    color: white;
    position: absolute;
    left: 0;
    right: 10px;
    bottom: 0;
    text-align: center;
    font-weight: bold;
    background: rgba(51, 51, 51, 0.5);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
</style>