<template>
    <div class="new-coupon">
        <div class="home-block-title">{{$t('title')}}</div>
        <Swipe
                :duration="swiper.duration"
                :initial-swipe="swiper.showIndex"
                :indicator-color="swiper.indicatorColor"
                :loop="false"
                :width="320"
                :height="250"
        >
            <template v-for='item in hotCoupon' >
                <SwipeItem :key="'item-'+item.id" @click="toCoupon(item.id)">
                    <div class="home-list">
                        <div class="shop_image">
                            <img :id='item.id' :src='item.image' />
                            <div class="remark_intro" v-if="item.remarkIntro">
                                <span class="quote">“</span>
                                <span>{{item.remarkIntro}}</span>
                                <span class="quote">”</span>
                            </div>
                        </div>
                        <div class="address">
                            <span class="title_name">{{item.name}}</span>
                            <span class="right">
                                {{item.store.name}}
                            </span>
                        </div>
                        <div class="price" v-if="item.couponData">
                            <span class="coupon-value">${{item.couponData.coupon_value}}</span>
                            <span class="face-falue">${{item.couponData.face_value}}</span>
                        </div>
                    </div>
                </SwipeItem>
            </template>
        </Swipe>
    </div>
</template>

<i18n>
{
    "en": {
        "title": "Hot Coupon"
    },
    "zh_CN": {
        "title": "精选折扣"
    }
}
</i18n>

<script>
import API from "@/utils/api";
import request from "@/utils/request";
import { mapGetters } from "vuex";
import {BaseMixin} from "@/utils/mixin";
import { img2fullURL } from "@/utils/helper";
import { Swipe, SwipeItem} from "vant";

export default {
    name: "HotCoupon",
    mixins: [BaseMixin],
    data: () => ({
        hotCoupon: [],
        swiper: {
            duration: 500,
            showIndex: 0,
            indicatorColor: '#FFFFFF'
        }
    }),
    components: {
        Swipe,
        SwipeItem
    },
    mounted() {
        if (this.$store.getters.isLocationAndCityLoaded) {
            this.loadHotCoupon();
        }
    },
    computed: {
        ...mapGetters(['isLocationAndCityLoaded']),
    },
    watch: {
        isLocationAndCityLoaded(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.loadHotCoupon();
            }
        },
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadHotCoupon();
            }
        },
    },
    methods: {
        loadHotCoupon() {
            let self = this;
            request({
                ...API.HOT_COUPON,
                data: {
                    ...this.$store.state.location,
                    city: this.$store.state.city.id
                }
            }).then(res => {
                if (res) {
                    res.forEach(item => {
                        console.log(item);
                        !item.name && (item.name = '');
                        item.image && (item.image = img2fullURL(item.image));
                        item.store && (item.store = item.store[0]);
                        if (item.couponData) {
                            if (typeof item.couponData === 'string')
                                item.couponData = JSON.parse(item.couponData);
                        }
                    })
                    console.log(res);
                    self.hotCoupon = [...res];
                }
            })
        },
        toCoupon(id) {
            this.$router.push('/coupon/' + id);
        }
    }
}
</script>

<style scoped>
.new-coupon {
    padding: 10px;
}
.home-list {
    width: 300px;
    height: 235px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
    /* margin: auto auto; */
    overflow: hidden;
    /* padding-bottom: 15rpx; */
}

.home-list.first{
    margin-top: 15px;
}
.home-list .shop_image {
    width: 100%;
    height: 175px;
    border-radius:5px 5px 0px 0px;
    overflow: hidden;
    position: relative;
}
.home-list .shop_image img {
    width: 100%;
    height: 175px;
}
.remark_intro{
    position: absolute;
    height: 30px;
    width: 100%;
    bottom: 0;
    font-family: PingFangSC-Semibold;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-shadow: 0 1px 4px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    background: rgba(0,0,0,0.25);
    padding-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.remark_intro .quote {
    font-size: 20px;
    letter-spacing: 1px;
    height: 100%;
}
.home-list .shop_name{
    width: 100%;
    height: 20px;
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-list .address{
    width: 282px;
    height: 15px;
    line-height:15px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    letter-spacing: 0;
    margin-left: 18px;
    margin-top: 8px;
}
.home-list .address span.right {
    width: 60px;
    position: absolute;
    right: 38px;
    text-align: right;
    line-height: 20px;
    height: 20px;
    color: gray;
}
.home-list .address .title_name {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #000000;
    /* letter-spacing: 0; */
    height: 20px;
    line-height: 20px;
}
.home-list .address_label{
    width: 282px;
    height: 15px;
    line-height: 15px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    letter-spacing: 0;
    margin-left: 18px;
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;
}
.home-list .address_label view.right {
    width: 50px;
    position: absolute;
    right: 38px;
    text-align: right;
    letter-spacing: 2px;
    color: #d9d9d9;
    /* margin-right: 40rpx; */
}
.home-list .address_label .title_name {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    height: 20px;
    line-height: 20px;
}
.price {
    margin-left: 16px;
    margin-top: 5px;
}
.coupon-value {
    font-size: 18px;
    color: #FF4D4F;
    text-shadow: 0 2px 4px rgba(255,77,79,0.24);
    margin-right: 10px;
}
.face-falue {
    color: #BFBFBF;
    margin-top: 3px;
    font-size: 14px;
    text-decoration:line-through;
}

</style>