<template>
    <div class="container">
        <div class="content">Checkout Successful!</div>
        <van-button round block type="info" @click="$router.replace('/my/coupon')">Use the coupon</van-button>
    </div>
</template>

<script>
import API from '@/utils/api';
import request from "@/utils/request";
import { hexMD5 } from "@/utils/MD5";
import _ from 'lodash';

export default {
    name: "CheckoutSuccess",
    data: () => ({
        paymentIntent: '',
        paymentIntentCS: ''
    }),
    mounted() {
        this.paymentIntent = this.$route.query.payment_intent;
        this.paymentIntentCS = this.$route.query.payment_intent_client_secret;
        this.placeOrder();
    },
    methods: {
        placeOrder() {
            let self = this;
            let coupon = this.$store.getters.getCoupon;
            if (_.isEmpty(coupon)) {
                coupon = JSON.parse(window.localStorage.getItem('coupon'));
            }
            console.log(coupon);
            const nonce = hexMD5(Math.random().toString(36).substr(2));
            const price = Number(coupon.couponData.coupon_value);
            const total = Number(price * coupon.count);

            if (self.paymentIntent) {
                request({
                    ...API.ORDER_PLACE,
                    data: {
                        productName: coupon.name,
                        nonce: nonce.substring(0, 30),
                        quantity: coupon.count,
                        orderType: coupon.couponTypeCode === 'DJQ' ? '1' : '2',
                        orderAmount: total,
                        noDiscountOrderAmount: 0,
                        paymentAmount: total,
                        discountAmount: 0,
                        orderFrom: '0',
                        merchantNo: coupon.merchantId,
                        merchantName: coupon.merchantName,
                        remark: '',
                        orderRefererId: coupon.id,
                        currency: "USD",
                        payTypeCode: 'stripe',
                        payWayCode: 'h5',
                        image: coupon.image,
                        paymentIntent: self.paymentIntent,
                        paymentIntentCS: self.paymentIntentCS
                    }
                }).then(() => {
                    self.$toast.success('Order placed!');
                });
            }
        },
    }
}
</script>

<style scoped>
.content {
    height: 200px;
    text-align: center;padding-top: 90px;
}
</style>