<template>
    <div>
        <van-cell-group>
            <van-cell
                icon="location-o"
                :title="currentTitle"
                center
            >
                <template #default>
                    <van-button size="small">{{$t('relocation')}}</van-button>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group title="选择地区">
            <van-grid :border="false" :column-num="3">
                <van-grid-item
                    v-for="city in cities"
                    :key="city.id"
                    :text="cityName(city)"
                    @click="chooseLocation(city)"
                >
                    <template #icon>
                        <img :src="city.img" alt="" class="city-avatar">
                    </template>
                </van-grid-item>
            </van-grid>
        </van-cell-group>
    </div>
</template>

<i18n>
{
    "en": {
        "relocation": "Relocation",
        "chooseLocation": "Choose Location"
    },
    "zh_CN": {
        "relocation": "重新定位",
        "chooseLocation": "选择地区"
    }
}
</i18n>

<script>
import request from "@/utils/request";
import API from "@/utils/api";
import {BaseMixin, pageMixin} from "@/utils/mixin";
export default {
    name: "ChooseLocation",
    mixins: [BaseMixin, pageMixin],
    data: () => ({
        cities: []
    }),
    mounted() {
        this.track();
        this.getCities();
        this.startLocating();
    },
    computed: {
        currentTitle() {
            if (this.$i18n.locale === 'zh_CN') {
                return this.$store.state.city.name || '定位中...';
            } else {
                return this.$store.state.city.enlishCityName || 'Loading...';
            }
        }
    },
    methods: {
        startLocating() {
            this.$store.dispatch('getGeoLocation');
        },
        getCities() {
            let self = this;
            request({
                ...API.CITY_LIST,
                params: {
                    countryCode: 'USA',
                    parentCityCode: '0'
                }
            }).then(res => {
                console.log(res);
                res = res.filter(city => {
                    return city.id === '56' || city.id === '57';
                });
                res.map(city => {
                    if (city.id === '56') {
                        city.img = require('@/assets/location/la.png');
                    } else {
                        city.img = require('@/assets/location/sf.png');
                    }
                    return city;
                });
                self.cities = res;
            })
        },
        chooseLocation(city) {
            this.$store.commit('updateCity', city);
            this.$router.back();
            console.log(city);
        },
        cityName(city) {
            if (this.$i18n.locale === 'zh_CN') {
                return city.cityName;
            } else {
                return city.enlishCityName;
            }
        }
    }
}
</script>

<style scoped>
    .city-avatar {
        height: 100px;
        width: 100px;
    }
</style>