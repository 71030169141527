// import store from "@/store";
import {IMAGE_URL, localeKey, TokenKey} from "@/utils/config";
import store from '@/store'
import router from '@/router'

export const defaultPosition = {
    latitude: 37.2961211,
    longitude: -121.9809025
}
export function getToken(fromRouter) {
    let token = '';
    if (store.state.token) {
        token = store.state.token;
    } else {
        token = window.localStorage.getItem(TokenKey);
    }
    if (token) {
        return token;
    } else {
        if (!fromRouter) {router.push({path: '/login'})}
        return false;
    }
}

export function getLocale() {
    let locale = '';
    if (store.state.user.locale) {
        locale = store.state.user.locale;
    } else {
        locale = window.localStorage.getItem(localeKey);
    }
    if (locale) {
        return locale;
    } else {
        return 'en';
    }
}

export function getCustomerLocation() {
    return JSON.parse(window.localStorage.getItem('customer-location'));
}
export function setCustomerLocation(position) {
    const location = {
        latitude: position.latitude,
        longitude: position.longitude
    }
    window.localStorage.setItem('customer-location', JSON.stringify(location));
}

export function message(data) {
    console.log(data);
}

export function img2fullURL(url) {
    if (url) {
        let urlAddon = '?imageslim';
        // if (app.globalData.platform === 'ios')
        //     urlAddon += '|imageMogr2/format/jpeg';
        // else
            urlAddon += '|imageMogr2/format/webp';
        return `${IMAGE_URL}${url}${urlAddon}`
    } else {
        return ``;
    }
}
export function formatTime(str) {
    str = replaceDate(str);
    const date = new Date(str);
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
}

export const formatDate = str => {
    str = replaceDate(str);
    const date = new Date(str);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return [year, month, day].map(formatNumber).join('-');
}
const replaceDate = str => {
    const p = str.indexOf('.');
    if (p != -1) {
        str = str.substring(0, p);
    }
    if (isIOS) {
        str = str.replace('T', ' ');
        str = str.replace(/-/g, '/');
    }
    return str;
}

export const formatOpenTime2 = list => {
    const day = [
        { zh_CN: "周日", en: "Sun.", range: ["休息"] },
        { zh_CN: "周一", en: "Mon.", range: ["休息"] },
        { zh_CN: "周二", en: "Tues.", range: ["休息"] },
        { zh_CN: "周三", en: "Wed.", range: ["休息"] },
        { zh_CN: "周四", en: "Thur.", range: ["休息"] },
        { zh_CN: "周五", en: "Fri.", range: ["休息"] },
        { zh_CN: "周六", en: "Sat.", range: ["休息"] }];
    list.forEach(item => {
        const start = item.businessDayStartStr.substring(0, 5);
        const end = item.businessDayEndStr.substring(0, 5);
        if (day[item.businessWeek].range[0] === '休息') {
            day[item.businessWeek].range[0] = `${start}-${end}`
        } else {
            day[item.businessWeek].range.push(`${start}-${end}`);
        }
    });
    return day;
};

export const findBusinessOfToday = businesses => {
    let today = new Date();
    let day = today.getDay();
    let todayBusinesses = businesses.filter(business => {
        return business.businessWeek === day
    })
    if (todayBusinesses.length === 0) {
        return;
    } else {
        let res = []
        todayBusinesses.forEach(business => {
            let businessStr = business.businessDayStartStr.substring(0, 5) + '-' + business.businessDayEndStr.substring(0, 5)
            res.push(businessStr)
        })
        return res;
    }
}

export const dateDiff = (firstDate, secondDate) => {
    let diff = Math.abs(firstDate.getTime() - secondDate.getTime())
    let result = parseInt(diff / (1000 * 60 * 60 * 24));
    return result
}
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

export function isIOS() {
    const u = navigator.userAgent;
    return /(iPhone|iPad|iPod|iOS)/i.test(u);
}

export function getDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = lat1*Math.PI / 180.0;
    var radLat2 = lat2*Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
        Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
    s = s *6378.137 ;// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return (s/1.609344).toFixed(2);
}