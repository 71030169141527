<template>
    <div class="content">
        <StoreBanner :banners="banners" />
        <StoreInfo :info="info" :today-businesses="todayBusinesses" />
        <AddressInfo :info="info" />
        <StoreMenu :menu="menu" v-if="menu.length > 0" />
        <StoreDish :top-food="topFood" v-if="topFood.length > 0" />
        <CouponList :dish="dish" :coupon="coupon" v-if="coupon" />
        <Remark :info="info" />
        <MoreDetail :info="info" :open-time="openTime" />
    </div>
</template>

<i18n>
{
    "en": {
        "title": "Hot Store"
    },
    "zh_CN": {
        "title": "精选餐厅"
    }
}
</i18n>

<script>
import {
    // formatTime,
    formatDate,
    img2fullURL,
    formatOpenTime2,
    findBusinessOfToday,
    dateDiff,
    getDistance,
} from "@/utils/helper";
import API from "@/utils/api";
import request from "@/utils/request";
import {BaseMixin, pageMixin} from "@/utils/mixin";
import AddressInfo from "@/components/StoreDetail/AddressInfo.vue";
import Remark from "@/components/StoreDetail/Remark.vue";
import MoreDetail from "@/components/StoreDetail/MoreDetail.vue";
import CouponList from "@/components/StoreDetail/CouponList.vue";
import StoreBanner from "@/components/StoreDetail/StoreBanner.vue";
import StoreInfo from "@/components/StoreDetail/StoreInfo.vue";
import StoreMenu from "@/components/StoreDetail/StoreMenu.vue";
import StoreDish from "@/components/StoreDetail/StoreDish.vue";

export default {
    name: "StoreDetail",
    components: {
        StoreDish,
        StoreMenu,
        StoreInfo,
        StoreBanner,
        CouponList,
        AddressInfo,
        Remark,
        MoreDetail
    },
    mixins: [BaseMixin, pageMixin],
    data: () => ({
        merchantId: 0,
        icons: {
            "Cash Only": "CashOnly",
            "Wi-Fi": "Wi-Fi",
            "停车场": "Parking",
            "包间": "VIPRoom",
            "婴儿座椅": "BabySeat",
            "宠物": "Pet",
            "残疾设施": "DFTD",
            "素食": "Vegetarian",
            "预约": "Booking",
        },
        newIcons: [{
            name: "停车场",
            icon: "Parking",
            active: false,
            activeContent: "提供",
            inActiveContent: "不提供"
        },
            {
                name: "素食",
                icon: "Vegetarian",
                active: false,
                activeContent: "有",
                inActiveContent: "无"
            },
            {
                name: "Cash Only",
                icon: "CashOnly",
                active: false,
                activeContent: "Cash Only",
                inActiveContent: "无"
            },
            {
                name: "残疾设施",
                icon: "DFTD",
                active: false,
                activeContent: "有",
                inActiveContent: "无"
            },
            {
                name: "Wi-Fi",
                icon: "Wi-Fi",
                active: false,
                activeContent: "提供",
                inActiveContent: "不提供"
            },
            {
                name: "婴儿座椅",
                icon: "BabySeat",
                active: false,
                activeContent: "有",
                inActiveContent: "无"
            },
            {
                name: "预约",
                icon: "reserved",
                active: false,
                activeContent: "接受",
                inActiveContent: "不接受"
            },
            {
                name: "宠物",
                icon: "Pet",
                active: false,
                activeContent: "是",
                inActiveContent: "否"
            },
            {
                name: "包间",
                icon: "VIPRoom",
                active: false,
                activeContent: "有",
                inActiveContent: "无"
            },
        ],
        actionMenus: [{
            name: '优惠',
            id: 'deal'
        },
            {
                name: '菜单·推荐',
                id: 'menu'
            },
            {
                name: '吃货点评',
                id: 'remark'
            },
            {
                name: '更多',
                id: 'more'
            }
        ],
        banners: [],
        info: {},
        topFood: [],
        menu: [],
        businessTime: '周一至周日 10:00AM-23:00PM',
        // 优惠券|代金券
        coupon: [],
        dish: [],
        comment: [],
        recommend: [],
        toView: '',
        nearby: [],
        day: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        openTime: [],
        todayBusinesses: []
    }),
    mounted() {
        this.merchantId = this.$route.params.id;
        this.getTopFood();
        this.getMenu();
        this.getInfo();
        this.getCouponList();
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getTopFood();
                this.getMenu();
                this.getInfo();
                this.getCouponList();
            }
        },
    },
    methods: {
        getOpenTime: function() {
            let arr = [];
            this.day.forEach(item => {
                let tempArr = this.info.openTime.filter(temp => temp.indexOf(item) >= 0);
                if (tempArr.length > 0) {
                    let tempStr = "";
                    for (let i = 0; i < tempArr.length; i++) {
                        if (i !== 0) {
                            tempArr[i] = tempArr[i].replace(item, "");
                        }
                        tempStr += tempArr[i];
                    }
                    arr.push(tempStr);
                }
            });
            this.openTime = arr;
        },
        // 推荐菜
        getTopFood: function() {
            let self = this;
            request({
                ...API.STORE_TOP_FOOD,
                showLoading: true,
                data: {
                    storeId: this.merchantId
                }
            }).then((res) => {
                if (res) {
                    res.forEach(item => {
                        item.storeDishImg && (item.storeDishImg = img2fullURL(item.storeDishImg));
                    });
                    self.topFood = res;
                }
            });
        },
        // 商家菜单
        getMenu: function() {
            let self = this;
            request({
                ...API.STORE_MENU,
                showLoading: true,
                data: {
                    storeId: this.merchantId
                },
            }).then((res) => {
                if (res) {
                    res.forEach(item => {
                        item.storeMenuImg && (item.storeMenuImg = img2fullURL(item.storeMenuImg));
                    });
                    self.menu = res;
                }
            });
        },
        getInfo: function() {
            let self = this;
            request({
                ...API.STORE_INFO,
                method: 'GET',
                showLoading: true,
                params: {
                    id: this.merchantId
                }
            }).then((res) => {
                if (res) {
                    console.log(res);
                    !res.name && (res.name = '');
                    !res.nameEnglish && (res.nameEnglish = '');
                    res.fullName = `${res.name} ${res.nameEnglish}`;
                    res.masterMapImg = img2fullURL(res.masterMapImg);
                    res.label && (res.label = res.label.split('|'));
                    res.service && (res.service = res.service.split('|'));
                    res.openTime = formatOpenTime2(res.businesses);
                    res.distance = getDistance(
                        self.$store.state.location.latitude,
                        self.$store.state.location.longitude,
                        res.addressLatitude,
                        res.addressLongitude
                    )
                    if (res.service) {
                        this.newIcons.forEach((value) => {
                            res.service.find(temp => temp == value.name ? value.active = true : value.active = false)
                        });
                    }
                    const temp = this.topFood.slice(0, 5).map(item => {
                        return {
                            img: item.storeDishImg
                        }
                    });
                    const banners = [{
                        img: res.masterMapImg
                    }, ...temp];
                    const businessesOfToday = findBusinessOfToday(res.businesses);
                    self.banners = banners;
                    self.openTime = res.openTime;
                    self.todayBusinesses = businessesOfToday;

                    if (res.benefits) {
                        res.benefits.forEach(bene => {
                            bene.unfolded = false

                            let startTime = bene.startTime
                            let endTime = bene.endTime
                            let today = new Date()
                            let startDate = new Date(startTime)

                            bene.hasStart = startDate <= today

                            bene.endtimeDesc = this.calculateEndTimeDesc(startTime, endTime)
                        })
                    }

                    self.info = res;
                    self.$store.commit('updatePageTitle', self.info.name);
                    self.track(self.info.name);
                }
            });
        },
        calculateEndTimeDesc(time1, time2) {
            let today = new Date()
            let time1Date = new Date(time1)
            let time2Date = (new Date(time2))
            console.log('=================>', time1)
            if (time1Date > today) {
                return time1.slice(5, 10).split('-').join('.') + '开始'
            }else {
                let remainingDays = dateDiff(time2Date, today)
                if (remainingDays <= 0) {
                    return '已过期'
                }
                return '最后 ' + (remainingDays + 1) + ' 天！'
            }
        },
        // 获取餐厅优惠券
        getCouponList: function () {
            request({
                ...API.STORE_COUPON,
                showLoading: true,
                data: {
                    storeId: this.merchantId,
                    pageNum: 1,
                    pageSize: 10
                },
            }).then((res) => {
                if (res) {
                    let coupon = [];
                    let dish = [];
                    // console.log(res)
                    res.list.forEach(item => {
                        item.image && (item.image = img2fullURL(item.image));
                        if (item.couponData) {
                            if (typeof item.couponData === 'string')
                                item.couponData = JSON.parse(item.couponData);
                        }
                        if (item.powerDescription) {
                            if (typeof item.powerDescription === 'string')
                                item.powerDescription = item.powerDescription.split('|');
                        }
                        item.startTime && (item.startTime = formatDate(item.startTime));
                        item.endTime && (item.endTime = formatDate(item.endTime));
                        if (item.couponTypeCode === 'TCQ') {
                            dish.push(item);
                        } else {
                            coupon.push(item);
                        }
                    });
                    this.coupon = coupon;
                    this.dish = dish;
                }
            });
        },

    }
}
</script>

<style scoped>
</style>