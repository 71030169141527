<template>
    <div>
        <template v-if="order">
            <div class="title title-price">${{order.paymentAmount}}</div>
            <div class="title title-merchant">{{order.merchantName}}</div>
            <div class="title title-coupon">{{order.productName}}</div>
        </template>
        <van-collapse v-model="paymentMethod" accordion :title="$t('payments')" @change="changeMethod">
            <van-collapse-item :title="$t('cards')" name="card">
                <van-radio-group v-model="selected">
                    <van-cell-group>
                        <van-cell
                                v-for="(card, idx) in cards"
                                :key="idx"
                                :title="'**** ' + card.last4"
                                icon="card"
                                size="large"
                                clickable
                                @click="selected = card.id"
                        >
                            <template #right-icon>
                                <van-radio :name="card.id" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <div class="container">
                    <van-button
                            round
                            block
                            class="add-new-button"
                            type="info"
                            @click="showDialog = true"
                    >Add New Card</van-button>
                    <van-button
                            v-if="selected"
                            block
                            round
                            type="primary"
                            @click="payOrder"
                    >Confirm Payment</van-button>
                </div>
                <van-dialog
                        title="Add New Card"
                        v-model="showDialog"
                        :show-confirm-button="false"
                        :close-on-click-overlay="true"
                >
                    <add-new-card @card-added="cardAdded"/>
                </van-dialog>
            </van-collapse-item>
            <van-collapse-item title="Apple Pay" name="apple">
                <div id="payment-request-button">
                    <!-- A Stripe Element will be inserted here. -->
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<i18n>
{
    "en": {
        "payments": "Payment Methods",
        "add": "Add New Card",
        "cards": "Cards",
        "confirmPayment": "Confirm Payment",
        "paymentSuccess": "Payment Successfully",
        "totalPayment": "Total Payment"
    },
    "zh_CN": {
        "payments": "支付方式",
        "add": "添加新的银行卡",
        "cards": "银行卡",
        "confirmPayment": "确认支付",
        "paymentSuccess": "支付成功",
        "totalPayment": "支付订单"
    }
}
</i18n>

<script>
import request from "@/utils/request";
import API from "@/utils/api";
import AddNewCard from "@/components/Payment/AddNewCard.vue";
import {hexMD5} from "@/utils/MD5";
import {BaseMixin, pageMixin} from "@/utils/mixin";
export default {
    name: "OrderPayment",
    mixins: [BaseMixin, pageMixin],
    components: {
        AddNewCard
    },
    data: () => ({
        showDialog: false,
        cards: [],
        selected: null,
        order: null,
        paymentMethod: 'card'
    }),
    mounted() {
        this.$store.commit('updatePageTitle', '订单支付' );
        if (this.$route.params.id) {
            this.loadOrder(this.$route.params.id);
        }
        this.loadCards();
    },
    watch: {
        getLocale(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadOrder(this.$route.params.id);
            }
        },
    },
    methods: {
        submit() {
            this.$refs.paymentRef.submit()
        },
        tokenCreated (token) {
            console.log(token);
        },
        loadOrder(id) {
            let self = this;
            request({
                ...API.ORDER_FIND,
                params: {
                    orderId: id
                }
            }).then(res => {
                self.order = res;
            })
        },
        loadCards() {
            let self = this;
            request({
                ...API.MY_CARDS
            }).then(res => {
                self.cards = res;
            })
        },
        cardAdded(e) {
            this.loadCards();
            this.showDialog = false;
            this.selected = e.id;
        },
        payOrder(payTypeCode = 'stripe', clientSecret = '') {
            console.log('pay this order');
            let self = this;
            const nonce = hexMD5(Math.random().toString(36).substr(2));
            request({
                ...API.ORDER_PAYMENT,
                data: {
                    orderId: self.$route.params.id,
                    payTypeCode: payTypeCode,
                    payWayCode: 'h5',
                    bindCardId: self.selected,
                    nonce: nonce,
                    clientSecret
                }
            }).then(res => {
                console.log(res);
                self.$toast.success(self.$t('paymentSuccess'));
                self.$router.replace("/order/" + this.$route.params.id);
            })
        },
        changeMethod(e) {
            if (e === 'apple') {
                console.log('yes');
                this.loadApple();
            }
        },
        loadApple() {
            let self = this;
            self.$toast.loading({
                message: 'Loading...',
                forbidClick: true
            });
            const paymentRequest = this.$stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: self.$t('totalPayment'),
                    amount: parseFloat(self.order.orderAmount) * 100
                },
                requestPayerName: true,
                requestPayerEmail: true
            });
            const elements = this.$stripe.elements();
            const prButton = elements.create('paymentRequestButton', {
                paymentRequest
            });

            (async () => {
                const result = await paymentRequest.canMakePayment();
                if (result) {
                    prButton.mount('#payment-request-button');
                } else {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
                self.$toast.clear();
            })();

            paymentRequest.on('paymentmethod', async (ev) => {
                request({
                    ...API.ORDER_APPLE_PAY,
                    data: {
                        orderId: self.$route.params.id,
                    }
                }).then(res => {
                    console.log(res);
                    self.$stripe.confirmCardPayment(
                        res.clientSecret,
                        { payment_method: ev.paymentMethod.id },
                        { handleActions: false }
                    ).then(confirmResult => {
                        if (confirmResult.error) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            ev.complete('fail');
                        } else {
                            // Report to the browser that the confirmation was successful, prompting
                            // it to close the browser payment method collection interface.
                            ev.complete('success');
                            // Check if the PaymentIntent requires any actions and if so let Stripe.js
                            // handle the flow. If using an API version older than "2019-02-11" instead
                            // instead check for: `paymentIntent.status === "requires_source_action"`.
                            if (confirmResult.paymentIntent.status === "requires_action") {
                                // Let Stripe.js handle the rest of the payment flow.
                                self.$stripe.confirmCardPayment(res.clientSecret).then(function(result) {
                                    if (result.error) {
                                        // The payment failed -- ask your customer for a new payment method.
                                    } else {
                                        // The payment has succeeded.
                                        self.payOrder('applePay', res.clientSecret);
                                    }
                                });
                            } else {
                                // The payment has succeeded.
                                self.payOrder('applePay', res.clientSecret);
                            }
                        }
                    })
                })
            })
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
}
.title-price {
    font-size: 32px;
    font-weight: 500;
    margin-top: 20px;
}
.title-merchant, .title-coupon {
    font-size: 14px;
}
.title-coupon {
    margin-bottom: 20px;
}
.add-new-button {
    margin-bottom: 15px;
}
</style>