<template>
    <van-cell-group>
        <van-cell
            title-class="block-title"
            :value="$t('title')"
            :border="false"
        >
            <template #title>
                <div>{{$t('title')}}</div>
                <van-rate
                    class="title-star"
                    v-model="level"
                    allow-half
                    :size="14"
                    void-icon="star"
                    void-color="#eee"
                    readonly
                />
            </template>
        </van-cell>
        <van-cell
            title-class="store-remarkIntro"
            v-if="info.remarkIntro"
            :title="'“' + info.remarkIntro + '”'"
            :border="false"
        >
        </van-cell>
        <van-cell
            title-class="remark"
        >
            <template #title>
                <div v-html="info.remark"></div>
            </template>
        </van-cell>
    </van-cell-group>
</template>

<i18n>
{
    "en": {
        "title": "Rice rate"
    },
    "zh_CN": {
        "title": "Rice评分"
    }
}
</i18n>

<script>
export default {
    name: "RemarkBlock",
    props: {
        info: Object
    },
    computed: {
        level: {
            get() {
                return this.info.level;
            },
            set() {
                return;
            }
        }
    }
}
</script>

<style scoped>
.block-title {
    display: flex;
    align-items: center;
}
.title-star {
    margin-left: 14px;
}
.store-remarkIntro {
    border-left: 3px solid #ccc;
    padding-left: 16px;
}
.remark {
    font-size: 14px;
    color: #707070;
    line-height: 20px;
}
.remark p {
    margin-top: 0;
}
</style>