import axios from 'axios';
import store from '@/store';
import { getToken, getLocale } from '@/utils/helper';
import {baseUrl, prefix} from '@/utils/config';

const service = axios.create({
    baseURL: baseUrl + '/' + prefix,
    timeout: 30000
});

service.interceptors.request.use(config => {
    if (config.private) {
        config.headers['Authorization'] = 'Bearer ' + getToken();
        config.headers['token'] = getToken();
    }
    config.headers['locale'] = getLocale();
    if (config.loading) {
        window.Bus.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: 'Loading...',
            loadingType: 'spinner'
        })
    }
    return config
}, error => {
    console.error(error);
    window.Bus.$toast.clear();
    return Promise.reject(error);
});

service.interceptors.response.use(
    response => {
        window.Bus.$toast.clear();
        if (response.status === 200) {
            if (response.data.code === '300012') {
                store.dispatch('userLogout');
            } else if (response.data.code !== '000000') {
                window.Bus.$notify({ type:'warning', message: response.data.msg });
                Promise.reject(response)
            }
            return Promise.resolve(response.data.data);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        window.Bus.$toast.clear();
        console.error('err ' + error);
        if (error.response && error.response.statusText === 'Unauthorized') {
            window.Bus.$notify({ type:'warning', message: error.response.data.message });
            store.dispatch('userLogout');
        } else {
            window.Bus.$notify({ type:'warning', message: 'Internal Error' });
        }
        return Promise.reject(error)
    }
);

export default service
