<template>
    <div class="container">
        <van-cell-group>
            <div v-if="coupon" class="coupon-info">
                <div class="coupon-info-l">
                    <img :src="coupon.image" alt="" class="coupon-img">
                </div>
                <div class="coupon-info-c">
                    <div class="coupon-info-c-title">
                        {{ coupon.name }}
                    </div>
                    <div class='coupon-info-c-time'>
                        <template v-for="(itm, idx) in coupon.powerDescription">
                            <span v-if="idx===0" :key="'itm1-' + idx">{{itm?$t('power.notCombinedable'):$t('power.combinedable')}} | </span>
                            <span v-if="idx===1" :key="'itm2-' + idx">{{itm?$t('power.notRefundable'):$t('power.refundable')}} | </span>
                            <span v-if="idx===2" :key="'itm3-' + idx">{{itm?$t('power.notRefundableAfterExpired'):$t('power.refundableAfterExpired')}}</span>
                        </template>
                    </div>
                </div>
                <div class="coupon-info-r">
                    <div class="coupon-value" v-if="coupon.couponData">
                        <div class="coupon-value-l">$ {{coupon.couponData.face_value}}</div>
                        <div class="coupon-value-r">$ {{coupon.couponData.coupon_value}}</div>
                    </div>
                    <van-stepper
                        v-model="count"
                        theme="round"
                        button-size="22"
                        disable-input
                    />
                </div>
            </div>
            <van-cell value="0" :title="$t('credit')" />
            <van-cell value-class="total-cell">
                <template #default>
                    {{$t('discount')}} <span class="price">${{discount}}</span> {{$t('subtotal')}} <span class="price">${{subtotal}}</span>
                </template>
            </van-cell>
        </van-cell-group>
<!--        <div class="stripe-payment-form">-->
<!--            <van-skeleton :row="4" v-if="paymentFormLoading"/>-->
<!--            <stripe-element-payment-->
<!--                v-if="secretLoaded"-->
<!--                v-show="!paymentFormLoading"-->
<!--                @element-ready="paymentGetReady"-->
<!--                ref="paymentRef"-->
<!--                :pk="pk"-->
<!--                :elements-options="elementsOptions"-->
<!--                :confirm-params="confirmParams"-->
<!--                v-on:token="cardToken"-->
<!--            ></stripe-element-payment>-->
<!--        </div>-->

        <van-submit-bar
            :price="total * 100"
            button-text="Place Order"
            @submit="place"
            currency="$"
            :label="$t('total') "
            class="payment-form"
            text-align="left"
        />
    </div>
</template>

<i18n>
{
    "en": {
        "discount": "Discount:",
        "subtotal": "Subtotal:",
        "total": "Total: ",
        "credit": "Your Credit"
    },
    "zh_CN": {
        "discount": "优惠 ",
        "subtotal": "小计 ",
        "total": "合计 ",
        "credit": "您的积分"
    }
}
</i18n>

<script>
import { stripeKey, checkoutRedirect } from "@/utils/config";
// import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import API from "@/utils/api";
import request from "@/utils/request";
import _ from "lodash";
import {hexMD5} from "@/utils/MD5";

export default {
    name: "CheckoutPage",
    components: {
        // StripeElementPayment
    },
    data: () => ({
        stripe: null,
        pk: stripeKey,
        elementsOptions: {
            appearance: {}, // appearance options
        },
        confirmParams: {
            return_url: checkoutRedirect, // success url
        },
        coupon: {},
        count: 1,
        secretLoaded: false,
        paymentFormLoading: true,
    }),
    mounted() {
        this.coupon = this.getCoupon();
        this.$store.commit('updatePageTitle', '提交订单' );
        // this.generatePaymentIntent(this.total);
    },
    computed: {
        total() {
            if (!_.isEmpty(this.coupon)) {
                let price = Number(this.coupon.couponData.coupon_value);
                return Number(price * this.count);
            }
            return 0;
        },
        discount() {
            if (!_.isEmpty(this.coupon)) {
                const price = Number(this.coupon.couponData.coupon_value);
                const oldPrice = Number(this.coupon.couponData.face_value);
                return Number((oldPrice-price) * this.count);
            }
            return 0;
        },
        subtotal() {
            return this.total;
        },
    },
    methods: {
        async generatePaymentIntent (amount) {
            if (amount > 0) {
                const paymentIntent = await this.apiCallToGeneratePaymentIntent(amount); // this is just a dummy, create your own API call
                console.log(paymentIntent);
                this.elementsOptions.clientSecret = paymentIntent.clientSecret;
                this.secretLoaded = true;
            }
        },
        async apiCallToGeneratePaymentIntent(amount) {
            return request({
                ...API.PAYMENT_INTENT,
                data: {
                    amount: amount * 100
                }
            });
        },
        place() {
            let self = this;
            self.coupon.count = self.count;
            const nonce = hexMD5(Math.random().toString(36).substr(2));
            const price = Number(self.coupon.couponData.coupon_value);
            const total = Number(price * self.coupon.count);
            request({
                ...API.ORDER_PLACE,
                data: {
                    productName: self.coupon.name,
                    nonce: nonce.substring(0, 30),
                    quantity: self.coupon.count,
                    orderType: self.coupon.couponTypeCode === 'DJQ' ? '1' : '2',
                    orderAmount: total,
                    noDiscountOrderAmount: 0,
                    paymentAmount: total,
                    discountAmount: 0,
                    orderFrom: '0',
                    merchantNo: self.coupon.merchantId,
                    merchantName: self.coupon.merchantName,
                    remark: '',
                    orderRefererId: self.coupon.id,
                    currency: "USD",
                    payTypeCode: 'stripe',
                    payWayCode: 'h5',
                    image: self.coupon.image,
                }
            }).then((res) => {
                console.log(res)
                self.$toast.success('Order placed!');
                self.$router.replace('/order/' + res.orderId + '/payment');
            });
            // this.$toast.loading({
            //     duration: 2000,
            //     forbidClick: true,
            //     message: 'Loading...',
            //     loadingType: 'spinner'
            // })
            // this.$store.commit('setCoupon', this.coupon);
            // this.$refs.paymentRef.submit();
        },
        getCoupon() {
            const coupon = this.$store.getters.getCoupon;
            if (_.isEmpty(coupon)) {
                return JSON.parse(window.localStorage.getItem('coupon'))
            }
            return coupon
        },
        paymentGetReady() {
            this.paymentFormLoading = false;
        },
        cardToken(token) {
            console.log(token);
        }
    }
}
</script>

<style scoped>
.coupon-info {
    display: flex;
    padding: 5px;
    height: 68px;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px 16px;
}
.coupon-info-l {
    width: 20%;
    height: 100%;
}
.coupon-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}
.coupon-info-c {
    flex: 1;
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.coupon-info-c-time {
    font-size: 14px;
}

.coupon-value {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.coupon-value-l {
    text-decoration: line-through;
    color: #707070;
    font-size: 12px;
    padding: 3px;
    flex-grow: 1;
    text-align: right;
}
.coupon-value-r {
    color: red;
    padding: 2px;
}
.total-cell {
    text-align: right;
}
.total-cell .price {
    color: red;
}
.stripe-payment-form {
    padding: 30px 16px;
    background-color: white;
}
</style>