<template>
    <div>
        <van-cell-group inset style="margin-top: 20px">
            <van-cell
                v-for="(card, idx) in cards"
                :key="idx"
                :title="'**** ' + card.last4"
                icon="card"
                size="large"
            >
                <template #right-icon>
                    <van-icon name="delete-o" class="delete-icon" @click="deleteCard(card.id)"/>
                </template>
            </van-cell>
        </van-cell-group>
        <div class="container">
            <van-button
                round
                block
                class="add-new-button"
                type="info"
                icon="plus"
                @click="showDialog = true"
            >{{$t('add')}}</van-button>
        </div>
        <van-dialog
            title="Add New Card"
            v-model="showDialog"
            :show-confirm-button="false"
            :close-on-click-overlay="true"
        >
            <add-new-card @card-added="cardAdded"/>
        </van-dialog>
    </div>
</template>

<i18n>
{
    "en": {
        "add": "Add New Card",
        "deleteCard": "Are you sure to delete this card?"
    },
    "zh_CN": {
        "add": "添加新的银行卡",
        "deleteCard": "你确定要删除这张银行卡吗?"
    }
}
</i18n>

<script>
import API from "@/utils/api";
import request from "@/utils/request";
import AddNewCard from "@/components/Payment/AddNewCard.vue";
export default {
    name: "PaymentPage",
    components: {
        AddNewCard
    },
    data: () => ({
        showDialog: false,
        cards: []
    }),
    mounted() {
        this.loadCards();
    },
    methods: {
        loadCards() {
            let self = this;
            request({
                ...API.MY_CARDS
            }).then(res => {
                self.cards = res;
            })
        },
        deleteCard(id) {
            let self = this;
            this.$dialog.confirm({
                title: self.$t('warning'),
                message: self.$t('deleteCard'),
                cancelButtonText: self.$t('cancel'),
                confirmButtonText: self.$t('confirm')
            }).then(() => {
                request({
                    ...API.CARD_DETELE,
                    data: {
                        card_id:  id
                    }
                }).then(() => {
                    self.loadCards();
                })
            })
        },
        cardAdded() {
            this.loadCards();
            this.showDialog = false;
        }
    }
}
</script>

<style scoped>
.add-new-button {
    margin-top: 10px;
}
.delete-icon {
    font-size: 16px;
    line-height: inherit;
}
</style>