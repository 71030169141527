<template>
    <div
        class="restaurant_detail merchant-block"
        id="more"
        v-if="info.businesses && info.businesses.length > 0"
    >
        <div class="block-title">{{$t('title')}}</div>
        <div class="rest_detail rest_business_hours">
            <div class="inline_block l_img">
                <van-icon name="underway-o"></van-icon>
            </div>
            <div class="inline_block r_content">
                <div class="rest_tag_title rest_title_active">{{$t('openTime')}}</div>
            </div>
        </div>
        <table class="openTime rest_content_active">
            <tr :key="idx" v-for="(item, idx) in openTime">
                <td class="week_day">{{item[$i18n.locale]}}</td>
                <td v-for="time in item.range" class="week_day_range" :key="time">{{time}}</td>
            </tr>
        </table>
<!--        <div class="rest_detail rest_detail_container">-->
<!--            <div class="rest_detail_block" v-for="(item, index) in newIcons" :key="index" v-if="item.active">-->
<!--                <div class="rest_detail_block_icon">-->
<!--                    <img style="width: 30rpx; height: 27rpx;" src="/images/service/{{item.icon}}.png" />-->
<!--                </div>-->
<!--                <div class="rest_detail_block_content">-->
<!--                    <div class="rest_tag_title {{item.active ? 'rest_title_active': 'rest_title_inActive'}}">{{item.name}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<i18n>
{
    "en": {
        "title": "More Information",
        "openTime": "Open Time"
    },
    "zh_CN": {
        "title": "更多信息",
        "openTime": "营业时间"
    }
}
</i18n>


<script>
export default {
    name: "MoreDetail",
    props: {
        info: Object,
        openTime: Array
    }
}
</script>

<style scoped>
.merchant-block {
    padding: 10px 16px;
}
.rest_detail{
    margin: 16px 0 0 16px;
}
.rest_detail img{
    width: 16px;
    height: 14px;
    padding: 0 16px 0 0;
}
.inline_block {
    display: inline-block;
    vertical-align: top;
}
.rest_tag_title {
    font-size: 14px;
}
.rest_tag_content {
    font-size: 14px;
}
.openTime {
    padding-left: 48px;
    font-size: 14px;
}
.week_day, .week_day_range {
    padding-right: 5px;
}

.rest_title_active{
    color: #000000;
    width: 74px;
    padding-left: 16px;
}
.rest_title_inActive{
    color: #D8D8D8;
}
.rest_content_active{
    color: #707070;
}
.rest_content_inActive{
    color: #D8D8D8;
}
</style>