<template>
    <van-tabs v-model="activeType" @change="updateCoupons">
        <van-tab title="Available">
            <van-pull-refresh v-model="isLoading" @refresh="loadMyCoupons(1)">
                <CouponItem v-for="item in couponsA" :item="item" :key="item.id" />
            </van-pull-refresh>
        </van-tab>
        <van-tab title="Not Available" >
            <van-pull-refresh v-model="isLoading" @refresh="loadMyCoupons(0)">
                <CouponItem v-for="item in couponsNA" :item="item" :key="item.id" :disabled="true" />
            </van-pull-refresh>
        </van-tab>
    </van-tabs>
</template>

<script>
import API from "@/utils/api";
import request from "@/utils/request";
import {formatDate, img2fullURL} from "@/utils/helper";
import {BaseMixin} from "@/utils/mixin";
import CouponItem from "@/components/MyCoupon/CouponItem";
import _ from "lodash";
export default {
    name: "MyCoupon",
    components: {CouponItem},
    mixins: [BaseMixin],
    data: () => ({
        couponsA: [],
        couponsNA: [],
        activeType: 0,
        isLoading: false
    }),
    mounted() {
        this.loadMyCoupons(1);
    },
    methods: {
        loadMyCoupons(type) {
            let self = this;
            request({
                ...API.COUPON_MINE,
                data: {
                    pageNum: 1,
                    pageSize: 10,
                    validType: type
                }
            }).then(res => {
                let coupons = [];
                res.list.forEach(item => {
                    item.image && (item.image = img2fullURL(item.image));
                    if (item.couponData) {
                        if (typeof item.couponData === 'string')
                            item.couponData = JSON.parse(item.couponData);
                    }
                    if (item.powerDescription) {
                        if (typeof item.powerDescription === 'string')
                            item.powerDescription = item.powerDescription.split('|');
                    }
                    item.startTime && (item.startTime = formatDate(item.startTime));
                    item.endTime && (item.endTime = formatDate(item.endTime));
                    coupons.push(item);
                });
                if (type === 0) {
                    self.couponsNA = coupons;
                } else {
                    self.couponsA = coupons;
                }
                self.isLoading = false;
            })
        },
        updateCoupons(e) {
            const type = e === 0? 1 : 0;
            let loaded = false;
            if (
                (type === 1 && !_.isEmpty(this.couponsA))
                || (type === 0 && !_.isEmpty(this.couponsNA))
            ) {
                loaded = true;
            }
            if (!loaded) {
                this.loadMyCoupons(type);
            }
        }
    }
}
</script>

<style scoped>

.coupon_deals_left img {
    width: 100%;
    height: 100%;
}


.coupon_deals_price span {
    margin-right: 11px;
}

.coupon_deals .coupon_more {
    right: 26px;
}
</style>