<template>
    <div id="app">
        <SiteHeader />
        <transition name="van-fade">
            <router-view />
        </transition>
    </div>
</template>

<script>
// import request from "@/utils/request";
// import API from "@/utils/api";
// import {getToken} from "@/utils/helper";

import SiteHeader from "@/components/SiteHeader.vue";

export default {
    components: {SiteHeader},
    mounted() {
        // let self = this;
        // if (!this.$store.getters.isUserLogin) {
        //     if(getToken()) {
        //         request({
        //             ...API.USER_ME
        //         }).then(res => {
        //             self.$store.commit('updateUser', res);
        //         });
        //     }
        // }
        this.$store.dispatch('loadUserInfo');
        this.$store.dispatch('getGeoLocation');
    }
}

</script>

<style lang="less">
html, body {
    height: 100%;
}
#app {
    background-color: #F8F8F8;
    height: 100%;
    overflow-y: scroll;
}
.container {
    padding: 10px 16px;
}
</style>
