import { mapGetters } from "vuex";

export let BaseMixin = {
    data: () => ({
    }),
    mounted() {
        this.$store.commit('updatePageTitle', this.$route.meta.locale[this.$i18n.locale]);
    },
    computed: {
        ...mapGetters(['getLocale'])
    },
    methods: {
        calPrice(price) {
            if (price <= 10) {
                return {
                    active: "$",
                    inactive: "$$$",
                    count: 1
                };
            } else if ( price <= 30 ) {
                return {
                    active: "$$",
                    inactive: "$$",
                    count: 2
                };
            } else if (price <= 60 ) {
                return {
                    active: "$$$",
                    inactive: "$",
                    count: 3
                };
            } else {
                return {
                    active: "$$$$",
                    inactive: "",
                    count: 4
                };
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        getOrderStatus(code, locale='en') {
            const statusList = [
                { en: 'Pending',    zh_CN: '待付款' },
                { en: 'Available',  zh_CN: '可使用' },
                { en: 'Payment Failed', zh_CN: '支付失败' },
                { en: 'Used', zh_CN: '已使用' },
                { en: 'Refunding', zh_CN: '待退款' },
                { en: 'Refunded', zh_CN: '已退款' },
                { en: 'Refund fail', zh_CN: '退款失败' },
                { en: 'Cancel', zh_CN: '已取消' },
                { en: 'Given', zh_CN: '已赠送' },
            ];
            return statusList[code][locale];
        },
    }
}

export let pageMixin = {
    mounted() {
    },
    methods: {
        track(pageName = this.$route.name) {
            this.$gtag.pageview({
                page_title: pageName,
                page_path: this.$route.path
            });
        }
    }
}